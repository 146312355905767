import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-blog-read',
  templateUrl: './blog-read.component.html',
  styleUrls: ['./blog-read.component.css']
})
export class BlogReadComponent implements OnInit {
  loading:boolean=true
 token= localStorage.getItem('Token');
 role= localStorage.getItem('role');
 userid=localStorage.getItem('UserID')
 firstname= localStorage.getItem('firstname');
 lastname= localStorage.getItem('lastname');
 image= localStorage.getItem('image');
 singleblog
 singleBLOG:any
 homedata
 followers:any[];
 following:any[]

  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService,private route:ActivatedRoute) {}


  ngOnInit(): void {
    this.singleblog=+this.route.snapshot.paramMap.get('id');

    this.spinner.show();
    this._service.HomeData()
     .subscribe(
       data => {
        let res: any = data["data"];

     
         let resources2: any = data["data"]["followers"];
         let resources3: any = data["data"]["following"];
         this.homedata = res;

      
          this.followers = resources2;
          this.following = resources3;
         
  });

  this._service.post_ById(this.singleblog)
  .subscribe(
    data => {
     let res: any = data["data"];
      this.singleBLOG = res;
      this.loading=false
 
});


  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }

  followit(id)
  {
    this._service.follow_users(id)
    .subscribe(); 
    this.ngOnInit()
  }
  likedpost(id)
  {

    var UserID=localStorage.getItem('UserID')
    if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {

    this.router.navigate(['/login'])
    }

    else
    {

      this._service.like_post(id)
      .subscribe(); 
      this.ngOnInit()
    }

  }
  submitcomment(comment){
    console.log("form",comment.value,this.singleblog)
  this._service.add_comment(this.singleblog,comment.value).subscribe(
      data=>  {

        let error:number= data["error"];
        let message:string= data["message"];

        if(error==0)
        {
          comment.value=""
         this.ngOnInit()
       }

        else
        {
           Swal.fire({
              icon: 'error',
              title: '...عذرا',
              text:message,
            })



          this.loading=false;


        }




      }

    );
  }

}
