import { Component, OnInit,ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;

  loading:boolean=true
  postid
  consult
  idcerti
  textcerti
  singlepost
  singlePOST:any
  userposts:any[]
  test11
   imageselected:File
   format
   formatedit
token= localStorage.getItem('Token');
useridprofile
 imagesselect: any='';
 ImageURl:any
 p: number = 1;
 ImageURl1='./assets/images/default.png'
 followers:any[]
 certificatewriter:any
 imageprofile:File
 writerprofile:File
 profileData:any
 imageedit:File
 userid=localStorage.getItem('UserID')
 role= localStorage.getItem('role');
 state=localStorage.getItem('state');
 editprofileimage(file:FileList){
  this.imageprofile =file.item(0);
  var reader=new FileReader();
  reader.onload=(event:any)=>{
    this.ImageURl1=event.target.result;
  }
  reader.readAsDataURL(this.imageprofile);
}
editwriterimage(file:FileList){
  this.writerprofile =file.item(0);
  var reader=new FileReader();
  reader.onload=(event:any)=>{
    this.ImageURl1=event.target.result;
  }
  reader.readAsDataURL(this.writerprofile);
}
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService,private route:ActivatedRoute) {}
  scrollToTop()
  {
    window.scrollTo(0, 0);
  }
  onSelectFile(event) {

    const file = event.target.files&& event.target.files[0];
    if(event.target.files.length  > 0) 
    {
      console.log(event.target.files[0].name);
      this.test11 =event.target.files[0].name
      if (file) {

        this.imageselected =file
  
        var reader = new FileReader();
        if(file.type.indexOf('image')> -1){
          this.format = 'image';
        } else if(file.type.indexOf('video')> -1){
          this.format = 'video';
        }
        else{
          this.format = 'File';
        }
        reader.onload = (event) => {
          this.ImageURl = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(file);
        this.imageselected==null


      }
    }


    
  }
  onSeditFile(event) {

    const file = event.target.files&& event.target.files[0];
    if(event.target.files.length  > 0) 
    {
      console.log(event.target.files[0].name);
      this.test11 =event.target.files[0].name
      if (file) {

        this.imageedit =file
  
        var reader = new FileReader();
        if(file.type.indexOf('image')> -1){
          this.formatedit = 'image';
        } else if(file.type.indexOf('video')> -1){
          this.formatedit = 'video';
        }
        else{
          this.formatedit = 'File';
        }
        reader.onload = (event) => {
          this.ImageURl = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(file);
      }
    }


    
  }

  ngOnInit(): void {
    this.spinner.show();
    this.useridprofile=+this.route.snapshot.paramMap.get('id');

 
    this._service.user_follower(this.useridprofile)
    .subscribe(
      data => {
       let res: any = data["data"];
        this.followers = res;
         this.loading=false
 });

    this._service.user_posts(this.useridprofile)
    .subscribe(
      data => {

        let resources: any = data["data"]
 
        this.userposts = resources;


         this.loading=false
 });


 
 this._service.show_userBy(this.useridprofile)
 .subscribe(
   data => {
    let res: any = data["data"];
        let res1: any = data["data"]["certificate"];

     this.profileData = res;
     this.certificatewriter = res1;

      this.loading=false
});
  $('textarea').keyup(function() {    
    var characterCount = $(this).val().length,
        current_count = $('#current_count'),
        maximum_count = $('#maximum_count'),
        count = $('#count');    
        current_count.text(characterCount);        
});

  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }

  onconsult(event){

    this.consult=event.target.value;
    
  }
  userediting(firstname,lastname,phone,email){
    this.loading=true;
    console.log("uuuu",firstname.value,lastname.value,phone.value,email.value)
        this._service.update_userData(this.imageprofile,firstname.value,lastname.value,phone.value,email.value).subscribe(
        
            data =>{
              let error=data['error']
              let message=data['message']
              if (error==0)
              {     
                Swal.fire({
                  icon: 'success',
                  title: message,
                  showConfirmButton: false,
                  timer: 2000
                })
         
             let res:any=data["data"];
                let firstname = res["firstname"];
                let lastname:string= res["lastname"];
                let Phone:string= res["phone"];
                let Email:string= res["email"];
                let image:string= res["image"];
      
    
    
                localStorage.setItem('firstname',firstname);
                localStorage.setItem('lastname',lastname);
                localStorage.setItem('Phone',Phone);
                localStorage.setItem('Email',Email);
                localStorage.setItem('image',image); 

               
              this.ngOnInit()
                window.location.reload()       
                        this.loading=false;

  
              }

              else{
                Swal.fire({
                  icon: 'error',
                  title: message,
                })
                this.loading=false;

              }
   

     });}
 
     submiteditwriter(fn,ls,ph,em,cm){
      this.loading=true;
      console.log("hjk",fn.value,ls.value,ph.value,em.value,this.consult,cm.value)
          this._service.update_writerData(this.writerprofile,fn.value,ls.value,ph.value,em.value,this.consult,cm.value).subscribe(
          
              data =>{
                let error=data['error']
                let message=data['message']
                if (error==0)
                {     
                  Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 2000
                  })
                  localStorage.removeItem('firstname');
                  localStorage.removeItem('lastname');
                  localStorage.removeItem('Phone');
                  localStorage.removeItem('Email');
                  localStorage.removeItem('image');
                  let res:any=data["data"];
                  let firstname = res["firstname"];
                  let lastname:string= res["lastname"];
                  let Phone:string= res["phone"];
                  let Email:string= res["email"];
                  let image:string= res["image"];
        
      
      
                 localStorage.setItem('firstname',firstname);
                  localStorage.setItem('lastname',lastname);
                  localStorage.setItem('Phone',Phone);
                  localStorage.setItem('Email',Email);
                  localStorage.setItem('image',image);
  
                  this.ngOnInit()
                  window.location.reload()       
                          this.loading=false;
  
    
    
                }
  
                else{
                  Swal.fire({
                    icon: 'error',
                    title: message,
                  })
                  this.loading=false;
  
                }
     
  
       });}
     followit(id)
     {
       this._service.follow_users(id)
       .subscribe(); 
       this.ngOnInit()
     }
   
   
     favsreshared(id)
     {
       var UserID=localStorage.getItem('UserID')
       if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
   
       this.router.navigate(['/login'])
       }
   
       else
       {
   
         this._service.make_favorite(id)
         .subscribe(); 
         this.ngOnInit()
       }
   
     }
     likedpost(id)
     {
   
       var UserID=localStorage.getItem('UserID')
       if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
   
       this.router.navigate(['/login'])
       }
   
       else
       {
   
         this._service.like_post(id)
         .subscribe(); 
         this.ngOnInit()
       }
   
     }
   
     sharepost(id)
  {

    var UserID=localStorage.getItem('UserID')
    if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {

    this.router.navigate(['/login'])
    }

    else
    {

      this._service.reshare_post(id)
      .subscribe(

        data=>  {
          Swal.fire({
            icon: 'success',
            title: 'تم مشاركة المنشور بنجاح',
            showConfirmButton: false,
            timer: 1500
          })
        }
      ); 
      this.ngOnInit()
    }

  }
     deletepost(id)
     {
   
       var UserID=localStorage.getItem('UserID')
       if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
   
       this.router.navigate(['/login'])
       }
   
       else
       {
   
         this._service.delete_post(id)
         .subscribe(); 
         this.ngOnInit()
       }
   
     }
     submitPost(text){
      this.loading=true;
      console.log("ssssssssss",text.value,this.imageselected)
    this._service.insert_post(text.value,this.imageselected).subscribe(
        data=>  {
  
          let error:number= data["error"];
          let message:string= data["message"];
  
          if(error==0)
          {
  
            Swal.fire({
                icon: 'success',
                title:message ,
                showConfirmButton: false,
                timer: 2000
              })
             this.loading=false;
            this.closebutton.nativeElement.click();
            text.value=""
            this.imageselected=null
            this.ImageURl=null
      
            this.ImageURl=''
             this.ngOnInit()
            }
  
          else
          {
             Swal.fire({
                icon: 'error',
                title: '...عذرا',
                text:message,
              })
  
  
  
            this.loading=false;
  
  
          }
  
  
  
  
        }
  
      );
    }
    submitPost11(text2){
      this.loading=true;
      console.log("ssssssssss",text2.value,this.imageselected)
    this._service.insert_post(text2.value,this.imageselected).subscribe(
        data=>  {
  
          let error:number= data["error"];
          let message:string= data["message"];
  
          if(error==0)
          {
  
            Swal.fire({
                icon: 'success',
                title:message ,
                showConfirmButton: false,
                timer: 2000
              })
              this.loading=false;
              this.closebutton1.nativeElement.click();
              text2.value=""
              this.imageselected=null
              this.ImageURl=null
        
              this.ImageURl=''
               this.ngOnInit()
  
            }
  
          else
          {
             Swal.fire({
                icon: 'error',
                title: '...عذرا',
                text:message,
              })
  
  
  
            this.loading=false;
  
  
          }
  
  
  
  
        }
  
      );
    }
     oninsertcommentFile(id)
     {
       this.postid=id
   
     }
     submitcomment(comment){
     this._service.add_comment(this.postid,comment.value).subscribe(
         data=>  {
   
           let error:number= data["error"];
           let message:string= data["message"];
   
           if(error==0)
           {
            comment.value=''
           
            this.ngOnInit()
          }
   
           else
           {
              Swal.fire({
                 icon: 'error',
                 title: '...عذرا',
                 text:message,
               })
   
   
   
             this.loading=false;
   
   
           }
   
   
   
   
         }
   
       );
     }
     profile(id)
     {
      this.router.navigate(['/timeline',id]).then(() => {
        window.location.reload();
      }); 
     }
     certidata(id,text)
     {
       this.idcerti=id;
       this.textcerti=text
       console.log("certi",id,text)

     }

     submitcertiwriter(ceri){
      this.loading=true;

       console.log("dddd",this.idcerti,ceri.value)
      this._service.update_certificate(this.idcerti,ceri.value).subscribe(
          data=>  {
    
            let error:number= data["error"];
            let message:string= data["message"];
    
            if(error==0)
            {
    
              Swal.fire({
                  icon: 'success',
                  title:message ,
                  showConfirmButton: false,
                  timer: 2000
                })
                this.loading=false;
                this.closebutton3.nativeElement.click();

                this.ngOnInit()
    
              }
    
            else
            {
               Swal.fire({
                  icon: 'error',
                  title: '...عذرا',
                  text:message,
                })
    
    
    
                this.loading=false;

    
            }
    
    
    
    
          }
    
        );
      }

      editpost(id)
{
  this.singlepost=id
  console.log("singlepost",id)
  
  this._service.post_ById(this.singlepost)
  .subscribe(
    data => {
     let res: any = data["data"];
      this.singlePOST = res;
       this.loading=false
});
}

submiteditpost(edittect){
  this.loading=true;
this._service.edit_post(this.imageedit,edittect.value,this.singlepost).subscribe(
    data=>  {

      let error:number= data["error"];
      let message:string= data["message"];

      if(error==0)
      {

        Swal.fire({
            icon: 'success',
            title:message ,
            showConfirmButton: false,
            timer: 2000
          })
          this.loading=false;
          this.closebutton2.nativeElement.click();

          this.ngOnInit()

        }

      else
      {
         Swal.fire({
            icon: 'error',
            title: '...عذرا',
            text:message,
          })



        this.loading=false;


      }




    }

  );
}


submiteditpost1(editpostwriter){
  this.loading=true;
  console.log("editpostwriter",editpostwriter.value)
this._service.edit_post(this.imageedit,editpostwriter.value,this.singlepost).subscribe(
    data=>  {

      let error:number= data["error"];
      let message:string= data["message"];

      if(error==0)
      {

        Swal.fire({
            icon: 'success',
            title:message ,
            showConfirmButton: false,
            timer: 2000
          })
          this.loading=false;
          this.closebutton4.nativeElement.click();

          this.ngOnInit()

        }

      else
      {
         Swal.fire({
            icon: 'error',
            title: '...عذرا',
            text:message,
          })



        this.loading=false;


      }




    }

  );
}
}
