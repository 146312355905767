import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BlogsComponent } from './blogs/blogs.component';
import { VideosComponent } from './videos/videos.component';
import { EventsComponent } from './events/events.component';
import { WatchEventComponent } from './watch-event/watch-event.component';
import { BlogReadComponent } from './blog-read/blog-read.component';
import { LoginComponent } from './login/login.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { BasicComponent } from './basic/basic.component';
import { UserWriterComponent } from './user-writer/user-writer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {NgStepperModule} from 'angular-ng-stepper';
import { NgxSpinnerModule } from "ngx-spinner";
import  { HttpClientModule } from '@angular/common/http';
import {Ng2PageScrollModule} from 'ng2-page-scroll';

import {RouterModule} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { SinglePostComponent } from './single-post/single-post.component';
import { WritercatsComponent } from './writercats/writercats.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimelineComponent } from './timeline/timeline.component';
import { VideosHomeComponent } from './videos-home/videos-home.component';
import { ImagesHomeComponent } from './images-home/images-home.component';
import { AllfollowersComponent } from './allfollowers/allfollowers.component';
import { AllfollowingComponent } from './allfollowing/allfollowing.component';
import { DeptBlogsComponent } from './dept-blogs/dept-blogs.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';


// Firebase
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FollowersbyidComponent } from './followersbyid/followersbyid.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BlogsComponent,
    VideosComponent,
    EventsComponent,
    WatchEventComponent,
    BlogReadComponent,
    LoginComponent,
    UserTypeComponent,
    UserRegistrationComponent,
    BasicComponent,
    UserWriterComponent,
    SinglePostComponent,
    WritercatsComponent,
    TimelineComponent,
    VideosHomeComponent,
    ImagesHomeComponent,
    AllfollowersComponent,
    AllfollowingComponent,
    DeptBlogsComponent,
    ForgetpasswordComponent,
    FollowersbyidComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule, FormsModule, ReactiveFormsModule,  MatStepperModule,CdkStepperModule,NgStepperModule,
    NgxSpinnerModule,
    HttpClientModule,
    RouterModule,
    NgxPaginationModule,
    Ng2PageScrollModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot()    ,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule, // Only required for storage features
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
