import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators,FormArray,FormControl} from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-user-writer',
  templateUrl: './user-writer.component.html',
  styleUrls: ['./user-writer.component.css']
})
export class UserWriterComponent implements OnInit {
  public stepOneForm: FormGroup;
  public stepTwoForm: FormGroup;
  loading:boolean=false
  ImageURl:any
  consulation
  cats:any[]
  selectedImage:File
  writerRegistration = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    password_confirmation: new FormControl('', Validators.required),
    category_id: new FormControl('', Validators.required),
    faculty: new FormControl('', Validators.required),
    university: new FormControl('', Validators.required),
    faculty_from: new FormControl('', Validators.required),
    faculty_to:  new FormControl('', Validators.required),
    consultant_money: new FormControl(''),


}, ) 
  constructor(private fb: FormBuilder,private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService) { 
       this.stepOneForm = this.fb.group({
   
    });
    this.stepTwoForm = this.fb.group({
  
    });


    this.JoinUsForm = this.fb.group({
      certificate: this.fb.array([]),

    })
  }
  handlefileInput(file:FileList){
    this.selectedImage =file.item(0);
    var reader=new FileReader();
    reader.onload=(event:any)=>{
      this.ImageURl=event.target.result;
    }
    reader.readAsDataURL(this.selectedImage);
  }
  JoinUsForm: FormGroup;

  ngOnInit(): void {
    this._service.all_category()
    .subscribe(
      data => {
       let res: any = data["data"];
       this.cats = res;

 });
  }
  onconsulation(event){

    this.consulation=event.target.value;
    console.log(this.consulation)
    
  }
  newcertificate(): FormGroup {
    return this.fb.group({
      text: new FormControl('', [
        Validators.required
      ]),

    });
  }
  get certificate(): FormArray {
    return this.JoinUsForm.get('certificate') as FormArray;
  }
  addcertificate() {
    console.log("certificate",this.certificate.value);
    this.certificate.push(this.newcertificate());
    if(this.JoinUsForm.value!='')
    {
      console.log("notempty");

    }
    else
    {
      console.log("empty");


    }
  

  }


  removenewcertificate(i: number) {
    this.certificate.removeAt(i);
  }
  samproduct: boolean = false;
  onSubmit(fromDate) {
    this.loading = true


    if (this.JoinUsForm.valid) {

    
   /*      this._service.add_certification(fromDate.value).subscribe(
          res => {
            let error: any = res["error"];
            let message: any = res["message"];

            if (error == 0) {
            
              Swal.fire({
                icon: 'success',
                title: message,
                showConfirmButton: false,
                timer: 1500
              })        
              this.ngOnInit();
              this.loading = false


            }
            else {

              Swal.fire({
                icon: 'error',
                title: message,
              })
              this.loading = false

            }
          }); */
    
    }
    else {

      Swal.fire('برجاء ملئ الحقول بشكل صحيح ')

      this.loading = false

    }
  }

submitForm(fromDate){
    this.loading=true
    if(this.certificate.value!='')
    {
      if(fromDate.valid)
        {
          console.log("consulation",this.consulation)

   this._service.Writer_register(fromDate,this.selectedImage,this.consulation).subscribe(response =>{
    let error:number= response["error"];
    let message:string= response["message"];

    if(error==0){
       Swal.fire({
            icon: 'success',
            title: message,
            showConfirmButton: false,
            timer: 2000

          })
     
           this.loading=false
           let res:any=response["data"];
                let Token:string= res["token"];
                let UserID:number= res["id"];
                let firstname:string= res["firstname"];
                let lastname:string= res["lastname"];
                let Phone:string= res["phone"];
                let Email:string= res["email"];
                let image:string= res["image"];
                let role:string= res["role"];
                let state:string= res["state"];
    
    
                localStorage.setItem('res',res);
    
                localStorage.setItem('Token',Token);
                localStorage.setItem('UserID',UserID.toString());
                localStorage.setItem('firstname',firstname);
                localStorage.setItem('lastname',lastname);
    
                localStorage.setItem('Phone',Phone);
                localStorage.setItem('Email',Email);
                localStorage.setItem('image',image);
                localStorage.setItem('role',role);
                localStorage.setItem('state',state);
              this.router.navigate(['/Home']).then(() => {
                window.location.reload();
              });

          this._service.add_certification(this.JoinUsForm.value,UserID).subscribe(
            res => {
              let error: any = res["error"];
              let message: any = res["message"];
  
              if (error == 0) {
              
                Swal.fire({
                  icon: 'success',
                  title: message,
                  showConfirmButton: false,
                  timer: 1500
                })        
                this.ngOnInit();
                this.loading = false
  
              }
              else {
  
                Swal.fire({
                  icon: 'error',
                  title: message,
                })
                this.loading = false
  
              }
            });
    
        }

    else
    {
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 2000

      })
    }
    this.loading=false;}) 

     }


else{
  Swal.fire('برجاء ملئ الحقول بشكل صحيح ')


  this.loading=false

} 
     }

     else
     {
      Swal.fire('برجاء ادخال شهادة')


     }

  }
}
