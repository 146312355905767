import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from "@angular/router"
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var UserID=localStorage.getItem('UserID')
    if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
      this.router.navigate(['/login'], {
        queryParams: {
          return: state.url
        }
      });
      return false;

      } else {
        return true;

      }

    }
}









