
 <app-basic></app-basic>

  <!-- Main Contents -->
  <div class="main_content">
    <div class="mcontainer">

        <div class="profile user-profile bg-white rounded-2xl -mt-4 dir-content">

            <div class="profiles_banner">
                <img src="assets/images/avatars/profile-cover.jpg" alt="">
                <div class="profile_action absolute bottom-0 right-0 space-x-1.5 p-3 text-sm z-50 hidden lg:flex">
                    <a *ngIf="role=='2' && useridprofile == userid" style="cursor: pointer;" uk-toggle="target: #edituser" class="flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white space-x-1.5"> 
                        <span> تعديل </span>
                    </a> 
                <a *ngIf="role=='3' && useridprofile == userid" style="cursor: pointer;" uk-toggle="target: #editwriter" class="flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white space-x-1.5"> 
                      <span> تعديل </span>
                  </a> 
                
               
                
              </div>
            </div>
            <div class="profiles_content">

                <div class="profile_avatar">
                    <div class="profile_avatar_holder"> 
                        <img src="{{profileData?.image}}" alt="">
                    </div>
             
                    <div class="icon_change_photo" hidden> </div>
                </div>

                <div class="profile_info">
                    <h1> {{profileData?.firstname}} {{profileData?.lastname}} </h1>
                   
                </div>

            </div>

   

        </div>

        <div class="lg:flex lg:mt-8 mt-4 lg:space-x-8">
          <div class="space-y-5 flex-shrink-0 lg:w-7/12 dir-content">
           
      

                <div class="  bg-white shadow border border-gray-100 rounded-lg dark:bg-gray-900 lg:mx-0 p-4" uk-toggle="target: #create-post-modal" *ngIf="role=='2' && useridprofile == userid">
                    <div class="flex space-x-3" style="width: 100%;">
                        <img src="{{profileData?.image}}" class="w-10 h-10 rounded-full">
                        <span class="bg-gray-100 hover:bg-gray-200 flex-1 h-10 px-6 rounded-full" style="padding-top: 8px;">ما هو رأيك !  </span>
                    </div>
                    <br>
                    <div class="grid grid-flow-col pt-3 -mx-1 -mb-1 font-semibold text-sm" style="width: 100%;">
                        <div class="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer"> 
                          <svg class="bg-blue-100 h-9 mr-2 p-1.5 rounded-full text-blue-600 w-9 -my-0.5 hidden lg:block" data-tippy-placement="top" title="Tooltip" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          صور / فيديو / ملف
                        </div>
                      
                    </div> 
                </div>

                <div class="  bg-white shadow border border-gray-100 rounded-lg dark:bg-gray-900 lg:mx-0 p-4" uk-toggle="target: #create-post-modal1" *ngIf="role=='3' && useridprofile == userid">
                  <div class="flex space-x-3" style="width: 100%;">
                      <img src="{{profileData?.image}}" class="w-10 h-10 rounded-full">
                      <span class="bg-gray-100 hover:bg-gray-200 flex-1 h-10 px-6 rounded-full" style="padding-top: 8px;">ما هو رأيك !  </span>
                    </div>
                  <br>
                  <div class="grid grid-flow-col pt-3 -mx-1 -mb-1 font-semibold text-sm" style="width: 100%;">
                      <div class="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer"> 
                        <svg class="bg-blue-100 h-9 mr-2 p-1.5 rounded-full text-blue-600 w-9 -my-0.5 hidden lg:block" data-tippy-placement="top" title="Tooltip" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                        صور / فيديو / ملف
                      </div>
                    
                  </div> 
              </div>
          

            <div class="bg-white shadow border border-gray-100 rounded-lg dark:bg-gray-900 lg:mx-0 uk-animation-slide-bottom-small" *ngFor="let s of userposts ">
          
                <div *ngIf="s.reshare==0">
                <div class="flex justify-between items-center lg:p-4 p-2.5">
                    <div class="flex flex-1 items-center space-x-4">
                        <a  (click)="profile(s.user_id)" style="cursor: pointer;">    
                            <img src="{{s.user_image}}" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                        </a>
                        <div class="flex-1 font-semibold capitalize">
                            <a (click)="profile(s.user_id)" style="cursor: pointer;" class="text-black">{{s.firstname}} {{s.lastname}}</a>
                            <div class="text-gray-700 flex items-center space-x-2"> {{s.new_createdAt}}</div>
                            <div class="text-gray-700 flex items-center space-x-2" *ngIf="s.cat_name!=null"> في مجال {{s.cat_name}} </div>

                        </div>
                    </div>
                  <div>
                    <a > <i class="  icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i> </a>
                    <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" 
                    uk-drop="mode: click;pos: bottom-left;animation: uk-animation-slide-bottom-small" >
                  
                        <ul class="space-y-1">
                          <li> 
                              <a (click)="sharepost(s.id)" class="flex items-center share px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                               <i class="uil-share-alt mr-1"></i> مشاركة
                              </a> 
                          </li>
                          <li *ngIf="userid == useridprofile && role=='2'" > 
                            <a class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"  uk-toggle="target: #editPost" (click)="editpost(s.id)">
                                <i class="uil-edit-alt mr-1"></i>  تعديل البوست 
                               </a> 
                          </li>

                          <li *ngIf="userid == useridprofile && role=='3'" > 
                            <a class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"    uk-toggle="target: #editPostwiter" (click)="editpost(s.id)">
                                <i class="uil-edit-alt mr-1"></i>  تعديل البوست 
                               </a> 
                          </li>
                        
                          <li> 
                              <a  (click)="favsreshared(s.id)" *ngIf="s.favorite==false" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                               <i class="uil-favorite mr-1"></i>  إضافة مفضلات
                              </a> 

                              <a style="color: #fb5a75;" (click)="favsreshared(s.id)" *ngIf="s.favorite==true" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                  <i class="uil-favorite mr-1"></i>  إزالة مفضلات
                                 </a> 
                          </li>
                          <li>
                            <hr class="-mx-2 my-2 dark:border-gray-800">
                          </li>
                          <li  (click)="deletepost(s.id)" *ngIf="userid == useridprofile"> 
                              <a  class="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                               <i class="uil-trash-alt mr-1"></i>  مسح
                              </a> 
                          </li>
                        </ul>
                    
                    </div>
                  </div>
                </div>
                <a   [routerLink]="['/singlepost',s.id]">

                <div class="p-3 border-b dark:border-gray-700"  *ngIf="s.text!=null">
                  <p style="color: #495057;"> {{s.text}}</p>   
          
      
                  </div>
              </a>
                <div >
                 
                    <a [routerLink]="['/singlepost',s.id]" *ngIf="s.image!=null">  
                        <img src="{{s.image}}" alt="" class="max-h-96 w-full object-cover">
                    </a>

                    <video  controls *ngIf="s.video!=null" src="{{s.video}}" frameborder="0"
                    uk-video="automute: true" allowfullscreen uk-responsive class="w-full lg:h-64 h-40"></video>
                    <a target="_blank" *ngIf="s.file!=null" href="{{s.file}}">  
                      {{s.file}}
                    </a>
                </div>
    
     
              
           

                <div class="p-4 space-y-3 "> 
               

                    <div class="flex space-x-4 lg:font-bold">
                        <a  *ngIf="s.likes==false"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                          <div> {{s.likes_num}} </div>

                            <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                </svg>
                            </div>
                        </a>

                        <a *ngIf="s.likes==true"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                          <div  style="color:#2d86ff;">  {{s.likes_num}}</div>
                          <div class="p-2 rounded-full text-black lg:bg-gray-100" style="color:#2d86ff;">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                  <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                              </svg>
                          </div>
                        
                      </a>
                        <a class="flex  items-center space-x-2">
                          <div> {{s.comments_num}}</div>
                            <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                    <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                </svg>
                            </div>
                          
                        </a>
                        <a  class="flex share items-center space-x-2 flex-1 justify-end">
                          <div> {{s.reshare_num}}</div>

                            <div class="p-2 rounded-full text-black lg:bg-gray-100">
                            <a (click)="sharepost(s.id)" >  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                    <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                </svg></a>  
                            </div>
                        </a>
                    </div>
           
                    <div   class="border-t py-4 space-y-4 dark:border-gray-600">
                        <div class="flex" *ngFor="let ss of s.comments| slice:0:3">
                            <a (click)="profile(ss.user_id)" style="cursor: pointer;">          <div class="w-10 h-10 rounded-full relative flex-shrink-0">
                                <img src="{{ss.image}}" alt="" class="absolute h-full rounded-full w-full">
                            </div></a>
                            <div>
                                <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12  dark:bg-gray-800 dark:text-gray-100">
                                    <p class="leading-6">{{ss.comment}} </p>
                                    <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                                </div>
                               
                            </div>
                        </div>
                    
                    </div>
                 
                    <a style="color: #495057;"  [routerLink]="['/singlepost',s.id]" class=" hover:text-blue-600 hover:underline" *ngIf="s.comments_num>3">  عرض {{s.comments_num-3}} مزيد من التعليقات </a>
    
                    <div class="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t"  >
                        <form #form="ngForm" (ngSubmit)="submitcomment(comment)">
                        <input #comment name="comment" placeholder="أضف تعليقك.." class="userclienttt bg-transparent max-h-10 shadow-none px-5"  (change)="oninsertcommentFile(s.id)" >
                       
                      </form>
                    </div>
            
                </div>
                 </div>




                 <div *ngIf="s.reshare > 0">
                  <div class="flex justify-between items-center lg:p-4 p-2.5">
                      <div class="flex flex-1 items-center space-x-4">
                          <a (click)="profile(s.user_id)" style="cursor: pointer;">
                              <img src="{{s.user_image}}" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                          </a>
                          <div class="flex-1 font-semibold capitalize">
                              <a class="text-black" (click)="profile(s.user_id)" style="cursor: pointer;">{{s.firstname}} {{s.lastname}}</a>
                              <div class="text-gray-700 flex items-center space-x-2"> {{s.new_createdAt}}</div>
                              <div class="text-gray-700 flex items-center space-x-2" *ngIf="s.cat_name!=null"> في مجال {{s.cat_name}} </div>

                          </div>
                      </div>
                    <div>
                      <a > <i class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i> </a>
                      <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" 
                      uk-drop="mode: click;pos: bottom-left;animation: uk-animation-slide-bottom-small" >
                    
                          <ul class="space-y-1">
                            <li> 
                                <a  (click)="sharepost(s.main_post.post_id)" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                 <i class="uil-share-alt mr-1"></i> مشاركة
                                </a> 
                            </li>
                    
                          
                            <li> 
                              <a  (click)="favsreshared(s.id)" *ngIf="s.favorite==false" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                               <i class="uil-favorite mr-1"></i>  إضافة مفضلات
                              </a> 

                              <a style="color: #fb5a75;" (click)="favsreshared(s.id)" *ngIf="s.favorite==true" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                  <i class="uil-favorite mr-1"></i>  إزالة مفضلات
                                 </a> 
                          </li>
                            <li>
                              <hr class="-mx-2 my-2 dark:border-gray-800">
                            </li>
                            <li  (click)="deletepost(s.id)"  *ngIf="userid == useridprofile"> 
                                <a  class="flex items-center share px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                 <i class="uil-trash-alt mr-1"></i>  مسح
                                </a> 
                            </li>
                          </ul>
                      
                      </div>
                    </div>
                  </div>

            
                  <div style="border: 1px solid gray;">
                      <div class="flex justify-between items-center lg:p-4 p-2.5">
                          <div class="flex flex-1 items-center space-x-4">
                              <a  (click)="profile(s.main_post.user_id)" style="cursor: pointer;">
                                  <img src="{{s.main_post.user_image}}" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                              </a>
                              <div class="flex-1 font-semibold capitalize">
                                  <a class="text-black" (click)="profile(s.main_post.user_id)" style="cursor: pointer;">{{s.main_post.firstname}} {{s.main_post.lastname}}</a>
                                  <div class="text-gray-700 flex items-center space-x-2"> {{s.main_post.new_createdAt}}</div>
                                  <div class="text-gray-700 flex items-center space-x-2" *ngIf="s.main_post.cat_name!=null"> في مجال {{s.main_post.cat_name}} </div>

                              </div>
                          </div>
                  
                      </div>
   
                      
  
                          <div >
                              <a  [routerLink]="['/singlepost',s.main_post.post_id]">

                              <div class="p-3 border-b dark:border-gray-700" *ngIf="s.text!=null">
                                  <p style="color: #495057;"> {{s.text}}</p>   
                          
                      
                                  </div>
                              </a>
                              <a  [routerLink]="['/singlepost',s.main_post.post_id]" *ngIf="s.image!=null">  
                                  <img src="{{s.image}}" alt="" class="max-h-96 w-full object-cover">
                              </a>
    
                              <video   controls *ngIf="s.video!=null" src="{{s.video}}" frameborder="0"
                              uk-video="automute: true" allowfullscreen uk-responsive class="w-full lg:h-64 h-40"></video>
                              <a target="_blank" *ngIf="s.file!=null" href="{{s.file}}">  
                                {{s.file}}
                              </a>
                          </div>
                   
                          <div class="p-4 space-y-3"> 
                     
                              <div class="flex space-x-4 lg:font-bold">
                                
                                  <a  *ngIf="s.main_post.likes==false"  (click)="likedpost(s.main_post.post_id)"  class="flex share items-center space-x-2 flex-1 justify-end">
                                      <div> {{s.main_post.likes_num}} </div>
      
                                        <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                            </svg>
                                        </div>
                                    </a>
      
                                    <a *ngIf="s.main_post.likes==true"  (click)="likedpost(s.main_post.post_id)"  class="flex share items-center space-x-2 flex-1 justify-end">
                                      <div  style="color:#2d86ff;">  {{s.main_post.likes_num}}</div>
                                      <div class="p-2 rounded-full text-black lg:bg-gray-100" style="color:#2d86ff;">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                              <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                          </svg>
                                      </div>
                                    
                                  </a> 



                                  <a  class="flex  items-center space-x-2 justify-end">
                                      <div> {{s.main_post.comments_num}}</div>

                                      <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                              <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                          </svg>
                                      </div>
                                  </a>
                               
                              </div>
                        </div>
                  
                  </div>
      

                
             

                  <div class="p-4 space-y-3"> 
               
                      <div class="flex space-x-4 lg:font-bold">
                        
                          <a  *ngIf="s.likes==false"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                              <div> {{s.likes_num}} </div>

                                <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                    </svg>
                                </div>
                            </a>

                            <a *ngIf="s.likes==true"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                              <div  style="color:#2d86ff;">  {{s.likes_num}}</div>
                              <div class="p-2 rounded-full text-black lg:bg-gray-100" style="color:#2d86ff;">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                      <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                  </svg>
                              </div>
                            
                          </a>
                          
                          
                          <a  class="flex items-center space-x-2">
                              <div> {{s.comments_num}}</div>

                              <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                      <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                  </svg>
                              </div>
                          </a>
                          <a   class="flex items-center space-x-2 flex-1 justify-end">
                              <div> {{s.reshare_num}}</div>

                              <div class="p-2 rounded-full text-black lg:bg-gray-100">
                               <a  (click)="sharepost(s.main_post.post_id)">  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                      <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                  </svg></a> 
                              </div>
                          </a>
                      </div>
                 
      
                      <div class="border-t py-4 space-y-4 dark:border-gray-600">
                          <div class="flex" *ngFor="let ss of s.comments | slice:0:3">
                            <a  (click)="profile(ss.user_id)" style="cursor: pointer;">     
                                   <div class="w-10 h-10 rounded-full relative flex-shrink-0">
                                  <img src="{{ss.image}}" alt="" class="absolute h-full rounded-full w-full">
                              </div></a>
                              <div>
                                  <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12  dark:bg-gray-800 dark:text-gray-100">
                                      <p class="leading-6">{{ss.comment}}</p>
                                      <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                                  </div>
                                 
                              </div>
                          </div>
                      
                      </div>
                      <a style="color: #495057;" [routerLink]="['/singlepost',s.id]" class=" hover:text-blue-600 hover:underline" *ngIf="s.comments_num>3">  عرض {{s.comments_num-3}} مزيد من التعليقات </a>

      
                      <div class="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t"  >
                          <form #form="ngForm" (ngSubmit)="submitcomment(comment)">
                          <input #comment name="comment" placeholder="أضف تعليقك.." class="userclienttt bg-transparent max-h-10 shadow-none px-5"  (change)="oninsertcommentFile(s.id)" >
                         
                        </form>
                      </div>
                  </div>
                   </div>
  </div>
<!--     <div class="flex justify-center mt-6">
                    <pagination-controls (pageChange)="p = $event"  (click)="scrollToTop()"></pagination-controls>
                     
                  </div> -->
           
    
            </div>
          <div class="lg:w-4/12 space-y-6 dir-content">
          
       
          

              <div class="widget border-t pt-4">
                  <div class="flex items-center justify-between mb-4">
                      <div>
                          <h4 class="text-2xl -mb-0.5 font-semibold" *ngIf="profileData?.role=='3'"> المتابعين </h4>
                      
                      </div>
                      <a  [routerLink]="['/followersbyid',profileData?.id]"  class="text-blue-600"   *ngIf="followers?.length>6">عرض الكل</a>
                  </div>
                  <div class="grid grid-cols-3 gap-3 text-gray-600 font-semibold">
                      <a *ngFor="let s of followers| slice:0:6">  
                         <div class="avatar relative rounded-md overflow-hidden w-full h-24 mb-2"> 
                           <a (click)="profile(s.id)" style="cursor: pointer;">  <img src="{{s.image}}" alt="" class="w-full h-full object-cover absolute"></a> 
                          </div>
                     <div>  <a (click)="profile(s.id)" style="cursor: pointer;">  {{s.firstname}} {{s.lastname}}</a></div>
                      </a>
                  
                  </div>
              </div>

              <div class="widget border-t pt-4" *ngIf="profileData?.role!='2'">
                <div class="flex items-center justify-between mb-4">
                    <div>
                        <h4 class="text-2xl -mb-0.5 font-semibold">الشهادات </h4>
                   
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-3 text-gray-600 font-semibold">
                    <a *ngFor="let s of certificatewriter">  
                        
                        <div> {{s.text}} 
                            <a  *ngIf="useridprofile == userid" style="cursor: pointer;" uk-toggle="target: #editwritercerti" (click)="certidata(s.id,s.text)">  <i class="uil-edit-alt mr-1 float-left"></i></a>

                        </div>
                    </a>
                
                </div>
            </div>


          </div>
        </div>

    </div>
</div>


    <!--edituser -->
    <div id="edituser" class="create-post" uk-modal style="overflow-x: scroll;" dir="rtl" style="text-align: right;">
        <div style="overflow-x: scroll;"
            class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
      
            <div class="text-center py-4 border-b">
                <h3 class="text-lg font-semibold"> تعديل بيانات المستخدم </h3>
                <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
            </div>
            <form #form="ngForm" (ngSubmit)="userediting(firstname,lastname,phone,email)">
                <div class="container" style="padding: 10px 20px;">
                   
    
                      <div class="mb-3">
                        <label for="formFile" class="form-label">اختر صورة</label>
                        <input class="form-control" type="file" id="formFile" (change)="editprofileimage($event.target.files)"  >
                      </div>
              
                    <div class="mb-3">
                        <label  class="form-label">الاسم الاول</label>
                        <input type="text" name="firstname" #firstname class="form-control editinp" value="{{profileData?.firstname}}" >
                      </div>
                      <div class="mb-3">
                        <label class="form-label">الاسم الثاني</label>
                        <input type="text" name="lastname" #lastname  class="form-control editinp" value="{{profileData?.lastname}}" >
                      </div>
                      <div class="mb-3">
                        <label  class="form-label">رقم الموبايل</label>
                        <input type="number" name="phone" #phone  class="form-control editinp" value="{{profileData?.phone}}" >
                      </div>
                      <div class="mb-3">
                        <label  class="form-label">البريد الالكتروني</label>
                        <input type="email"   name="email" #email  class="form-control editinp"  value="{{profileData?.email}}">
                      </div>
    
                      <div class="mb-3 text-left">
                    <button  type="submit" class="btn btn-primary">تعديل</button>
                      </div>
                  </div>
        
        </form>
        </div>
      </div>

<!-- open chat box -->
<div uk-toggle="target: #offcanvas-chat" class="start-chat">
 <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
 </svg>
</div>

<div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true">
 <div class="uk-offcanvas-bar bg-white p-0 w-full lg:w-80">


     <div class="relative pt-5 px-4">

         <h3 class="text-2xl font-bold mb-2"> الدردشات </h3>

         <div class="absolute right-3 top-4 flex items-center">

             <button class="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                 type="button" uk-close></button>

           

         </div>


     </div>

     <div class="absolute bg-white z-10 w-full -mt-5 lg:mt-0 transform translate-y-1.5 py-2 border-b items-center flex"
         id="search" hidden>
         <input type="text" placeholder="Search.." class="flex-1">

     </div>

     <nav class="cd-secondary-nav border-b extanded mb-2">
         <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
             <li class="uk-active"><a class="active" href="#0"> أصدقاء </a></li>
             <li><a href="#0">مجموعات <span> 10 </span> </a></li>
         </ul>
     </nav>

     <div class="contact-list px-2 uk-switcher" id="chats-tab">

         <div>

             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-1.jpg" alt="">
             
                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-2.jpg" alt="">
                     <span class="user_status"></span>
                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-3.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-4.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>

         </div>
         <div>

             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-1.jpg" alt="">
              
                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-2.jpg" alt="">
                     <span class="user_status"></span>
                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-3.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-4.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>

         </div>

     </div>
 </div>
</div>



  <!-- Craete post modal -->
  <div id="create-post-modal" class="create-post" uk-modal style="overflow-x: scroll;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> إنشاء منشور </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton ></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submitPost(text)">
        <div class="flex flex-1 items-start space-x-4 p-5">
            <img src="{{profileData?.image}}"
                class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <div class="flex-1 pt-2" >
                <textarea  #text name="text" maxlength="140"  class="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="4"
                    placeholder="ما هو رأيك؟ {{profileData?.firstname}}!">

                </textarea>
                <div id="count">
                    <span id="maximum_count"> 140</span>
                </div>
                    <img *ngIf="format==='image' && ImageURl" src="{{ImageURl}}" style="width: 100%;" class="img-fluid">
                    <a *ngIf="format==='File' && ImageURl" href="{{ImageURl}}" >{{test11}}</a>
                    <video *ngIf="format==='video' && ImageURl" src="{{ImageURl}}"   style="width: 100%;"></video>

           
            </div>
           
  
        </div>
        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-3 shadow-sm items-center">
                <div class="lg:block hidden">أضف إلى منشورك </div>
                <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                     <span class="test" id="preview"> 
                          <a> 
                            <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          <input (change)="onSelectFile($event)"  style="opacity: 0" type="file"  accept="/*"/>
                            </a>
                       </span>

              
                </div>
            </div>
        </div>
        <div class="flex items-center w-full justify-between p-3 border-t">
          <button id="closeModal" type="submit"
                class="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
                نشر </button>
        </div>
    </form>
    </div>
  </div>

  <!-- Craete post modal -->
  <div id="create-post-modal1" class="create-post" uk-modal style="overflow-x: scroll;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> إنشاء منشور </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton1 ></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submitPost11(text2)">
        <div class="flex flex-1 items-start space-x-4 p-5">
            <img src="{{profileData?.image}}"
                class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <div class="flex-1 pt-2" >
                <textarea  #text2 name="text2"   class="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="4"
                    placeholder="ما هو رأيك؟ {{profileData?.firstname}}!">

                </textarea>
              
                    <img *ngIf="format==='image' && ImageURl" src="{{ImageURl}}" style="width: 100%;" class="img-fluid">
                    <a *ngIf="format==='File' && ImageURl" href="{{ImageURl}}" >{{test11}}</a>
                    <video *ngIf="format==='video' && ImageURl" src="{{ImageURl}}"   style="width: 100%;"></video>

           
            </div>
           
  
        </div>
        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-3 shadow-sm items-center">
                <div class="lg:block hidden">أضف إلى منشورك </div>
                <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                     <span class="test" id="preview"> 
                          <a> 
                            <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          <input (change)="onSelectFile($event)"  style="opacity: 0" type="file"  accept="/*"/>
                            </a>
                       </span>

              
                </div>
            </div>
        </div>
        <div class="flex items-center w-full justify-between p-3 border-t">
          <button id="closebutton1" type="submit" id="closebutton"
                class="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
                نشر </button>
        </div>
    </form>
    </div>
  </div>



  <div id="editwriter" class="create-post" uk-modal style="overflow-x: scroll;"  dir="rtl" style="text-align: right;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> تعديل بيانات الكاتب </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton ></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submiteditwriter(fn,ls,ph,em,cm)">
            <div class="container" style="padding: 10px 20px;">
               

                  <div class="mb-3">
                    <label for="formFile" class="form-label">اختر صورة</label>
                    <input class="form-control" type="file" id="formFile" (change)="editwriterimage($event.target.files)"  >
                  </div>
          
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">الاسم الاول</label>
                    <input type="text" name="fn" #fn class="form-control editinp" id="exampleFormControlInput1" value="{{profileData?.firstname}}">
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">الاسم الثاني</label>
                    <input type="text" name="ls" #ls  class="form-control editinp" id="exampleFormControlInput1" value="{{profileData?.lastname}}">
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">رقم الموبايل</label>
                    <input type="number" name="ph" #ph  class="form-control editinp" id="exampleFormControlInput1" value="{{profileData?.phone}}">
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">البريد الالكتروني</label>
                    <input type="email"   name="em" #em  class="form-control editinp" id="exampleFormControlInput1" value="{{profileData?.email}}">
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [checked]="profileData?.consultation=='1'" name="chooseconst" id="{{profileData?.consultation}}" value="1" (change)="onconsult($event)">
                    <label class="form-check-label" for="inlineRadio1">الرد على الاستشارات مجانا</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio"  [checked]="profileData?.consultation=='3'" name="chooseconst" id="{{profileData?.consultation}}" value="3" (change)="onconsult($event)">
                    <label class="form-check-label" for="inlineRadio2">بدون مراسلة</label>
                 
                  </div>
                  <div class="form-check form-check-inline mb-3 ">
                    <input class="form-check-input" type="radio"  [checked]="profileData?.consultation=='2'" name="chooseconst"  id="{{profileData?.consultation}}" value="2" (change)="onconsult($event)">
                    <label class="form-check-label" for="inlineRadio3">الرد على الاستشارات بمقابل مادى </label>
                    <input style="width: 50%;"   name="cm" #cm  class="form-control editinp"  value="{{profileData?.consultant_money}}">

                  </div>
                
                  <br>
                  <div class="mb-3 text-left">
                <button class="btn btn-primary" type="submit">تعديل</button>
                  </div>
              </div>
    
    </form>
    </div>
  </div>


  
<!--editwritercerti -->
<div id="editwritercerti" class="create-post" uk-modal style="overflow-x: scroll;" dir="rtl" style="text-align: right;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> تعديل شهادات الكاتب </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton3></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submitcertiwriter(ceri)">
            <div class="container" style="padding: 10px 20px;">
               

                <div class="mb-3">
                    <label  class="form-label">الشهادة</label>
                    <input type="text" name="ceri" #ceri class="form-control editinp"  value="{{textcerti}}">
                  </div>
                
               
               
           
                
                  <br>
                  <div class="mb-3 text-left">
                <button id="closebutton3" class="btn btn-primary" type="submit">تعديل</button>
                  </div>
              </div>
    
    </form>
        

    </div>
  </div>

<!-- editPost -->
<div id="editPost" class="create-post" uk-modal style="overflow-x: scroll;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> تعديل منشور </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton2></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submiteditpost(edittect)">
        <div class="flex flex-1 items-start space-x-4 p-5">
            <img src="{{profileData?.image}}"
                class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <div class="flex-1 pt-2">
                <textarea  #edittect name="edittect" maxlength="140"  class="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="4"
                    placeholder="ما هو رأيك؟ {{profileData?.firstname}}!" value={{singlePOST?.text}}>
           

                </textarea>
                <div id="count">
                    <span id="maximum_count">140</span>
                </div>
                    <img *ngIf="singlePOST?.image || formatedit==='image'  " src="{{singlePOST?.image}}" style="width: 100%;" class="img-fluid">
                    <a *ngIf="singlePOST?.file || formatedit==='File'  " href="{{singlePOST?.file}}" >{{singlePOST?.file}}</a>
                    <video *ngIf="singlePOST?.video || formatedit==='video' " src="{{singlePOST?.video}}"   style="width: 100%;"></video>

           
            </div>

  
        </div>
     


        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-3 shadow-sm items-center">
                <div class="lg:block hidden">أضف إلى منشورك </div>
                <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                     <span class="test" id="preview"> 
                          <a> 
                            <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          <input (change)="onSeditFile($event)"  style="opacity: 0" type="file"  accept="/*"/>
                            </a>
                       </span>

              
                </div>
            </div>
        </div>
        <div class="flex items-center w-full justify-between p-3 border-t">
          <button type="submit" id="closebutton2"
                class="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
                تعديل </button>
        </div>
    </form>
    </div>
  </div>


  <!-- editPost -->
<div id="editPostwiter" class="create-post" uk-modal style="overflow-x: scroll;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> تعديل منشور </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton4></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submiteditpost1(editpostwriter)">
        <div class="flex flex-1 items-start space-x-4 p-5">
            <img src="{{profileData?.image}}"
                class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <div class="flex-1 pt-2">
                <textarea  #editpostwriter name="editpostwriter" class="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="4"
                    placeholder="ما هو رأيك؟ {{profileData?.firstname}}!" value={{singlePOST?.text}}>
           

                </textarea>
               
                    <img *ngIf="singlePOST?.image || formatedit==='image'  " src="{{singlePOST?.image}}" style="width: 100%;" class="img-fluid">
                    <a *ngIf="singlePOST?.file || formatedit==='File'  " href="{{singlePOST?.file}}" >{{singlePOST?.file}}</a>
                    <video *ngIf="singlePOST?.video || formatedit==='video' " src="{{singlePOST?.video}}"   style="width: 100%;"></video>

           
            </div>

  
        </div>
     


        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-3 shadow-sm items-center">
                <div class="lg:block hidden">أضف إلى منشورك </div>
                <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                     <span class="test" id="preview"> 
                          <a> 
                            <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          <input (change)="onSeditFile($event)"  style="opacity: 0" type="file"  accept="/*"/>
                            </a>
                       </span>

              
                </div>
            </div>
        </div>
        <div class="flex items-center w-full justify-between p-3 border-t">
          <button type="submit" id="closebutton4"
                class="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
                تعديل </button>
        </div>
    </form>
    </div>
  </div>
<ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>