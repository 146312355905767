import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogReadComponent } from './blog-read/blog-read.component';
import { BlogsComponent } from './blogs/blogs.component';
import { EventsComponent } from './events/events.component';
import { HomeComponent } from './home/home.component';
import { VideosComponent } from './videos/videos.component';
import { WatchEventComponent } from './watch-event/watch-event.component';
import { LoginComponent } from './login/login.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { UserWriterComponent } from './user-writer/user-writer.component';
import { SinglePostComponent } from './single-post/single-post.component';
import { WritercatsComponent } from './writercats/writercats.component';
import {AuthenticationService  } from "./authentication.service";
import { TimelineComponent } from './timeline/timeline.component';
import { VideosHomeComponent } from './videos-home/videos-home.component';
import { ImagesHomeComponent } from './images-home/images-home.component';
import { AllfollowersComponent } from './allfollowers/allfollowers.component';
import { AllfollowingComponent } from './allfollowing/allfollowing.component';
import { DeptBlogsComponent } from './dept-blogs/dept-blogs.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { FollowersbyidComponent } from './followersbyid/followersbyid.component';

const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch:'full' },
  { path: 'Home',  component:HomeComponent},  
  { path: 'Blogs',  component:BlogsComponent}, 
  { path: 'Videos',  component:VideosComponent}, 
  { path: 'favs',  component:EventsComponent,canActivate: [AuthenticationService]}, 
  { path: 'WatchEvent',  component:WatchEventComponent},     
  { path: 'blodread/:id',  component:BlogReadComponent,canActivate: [AuthenticationService]},             
  { path: 'login',  component:LoginComponent},             
  { path: 'usertype',  component:UserTypeComponent},             
  { path: 'userRegistration',  component:UserRegistrationComponent},   
  { path: 'userWriter',  component:UserWriterComponent},             
  { path: 'singlepost/:id',  component:SinglePostComponent},             

  { path: 'writercat',  component:WritercatsComponent,canActivate: [AuthenticationService]},         
  { path: 'timeline/:id',  component:TimelineComponent,canActivate: [AuthenticationService]},             
  { path: 'Allfollowers',  component:AllfollowersComponent,canActivate: [AuthenticationService]},         
  { path: 'Allfollowing',  component:AllfollowingComponent,canActivate: [AuthenticationService]},         
  { path: 'DeptBlogs',  component:DeptBlogsComponent},         

  { path: 'videoshome',  component:VideosHomeComponent},             
  { path: 'imageshome',  component:ImagesHomeComponent},             
  { path: 'Forgetpassword',  component:ForgetpasswordComponent},             
  { path: 'followersbyid/:id',  component:FollowersbyidComponent,canActivate: [AuthenticationService]},             

  { path:  '**', redirectTo: 'Home', pathMatch:  'full'},

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
