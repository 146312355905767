import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
  loading:boolean=false
  rememberme:boolean=false
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router) {}

  ngOnInit(): void {
    this.spinner.show();

  }
  checkCheckBoxvalue(event){
    this.rememberme=event.target.checked
    console.log("dddd",event.target.checked)
  }

  submitForm(phone,password,confirmpassword){
    this.loading=true
 
      this._service.forget_password(phone.value,password.value).subscribe(
        data=>  {
          console.log("ff",phone.value,password.value)
     
          if(confirmpassword.value==password.value)
          {
    
            let error:number= data["error"];
            let message:string= data["message"];
    
            if(error==0){
             
                Swal.fire({
                  icon: 'success',
                  title: message,
                  showConfirmButton: false,
                  timer: 1500
                })
           this.loading=false
         
    
    
             
         
           this.router.navigate(['/login']).then(() => {
                window.location.reload();
              });  
    
                }
           else {
    
            Swal.fire({
              icon: 'error',
              title: message,
            })
        
            this.loading=false

            }
          }
          else
          {
           
              Swal.fire({
                icon: 'error',
                title: 'يرجي التحقق من مطابقة  كلمة السر',
              })
              this.loading=false
        
          }
          }
          
    
        );
    

    }


  
         

}
