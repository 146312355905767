 <div class="bg-body">
        <div class="account-pages">
            <div class="container">
              
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <div class="card card-login card-login5">
                            <div class="align-items-center justify-content-center"> 
                            <a  class="auth-logo">
                                <img src="assets/images/logoo.png" alt="" class="logo">

                            </a>
                        </div>
                            <div class="card-body"> 
                                <div class="text-center">

                                    <h5>تسجيل دخول الان</h5>
                                </div>
                                <div class="p-2 mt-4">
                                    <form #form="ngForm" (ngSubmit)="submitForm(email,password)">
        
                                        <div class="form-group position-relative">
                                            <input type="text" class="form-control"  #email name="email"   placeholder="البريد الألكتروني">
                                            <img class="icon-input" src="assets/images/username.png">
                                        </div>
                
                                        <div class="form-group position-relative">
                                            <input type="password" class="form-control" #password name="password"  placeholder="كلمة السر">
                                            <img class="icon-input" src="assets/images/password.png">
                                        </div>
                                        <div class="text-left">
                                            <a [routerLink]="'/Forgetpassword'" class="forget-pass">هل نسيت كلمة السر؟</a>
                                        </div>
                
                                        <div class="custom-control custom-checkbox">

                                            <input type="checkbox" class="custom-control-input" id="auth-remember-check"  (change)="checkCheckBoxvalue($event)">
                                            <label class="custom-control-label" for="auth-remember-check">تذكرني</label>
                                        </div>
                                        
                                        <div class="mt-3 text-center mb-10">
                                            <button class="btn btn-primary w-sm waves-effect waves-light" type="submit">تسجيل دخول الان</button>
                                        </div>
            

                                        <div class="text-center">
                                            <p class="mb-0"><a [routerLink]="'/usertype'" class="btn-new-account"> ليس لديك اى حساب؟ </a>
                                              </p>
                                              <p>    <a class="mr-3" [routerLink]="'/Home'" class="btn-new-account"> الصفحة الرئيسية </a></p>
                                        </div>
                                    </form>
                                </div>
            
                            </div>
                        </div>

                    

                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>

    </div>


    <ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>