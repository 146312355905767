import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable, of, } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {
Token=  localStorage.getItem('Token');
  constructor(private http: HttpClient) {
    console.log('Token',this.Token)
  }
  baseurl = environment.apiHost;
  httpOptions = {
    headers: new HttpHeaders({
      'content': 'application/json',
      'Authorization': 'Bearer ' +this.Token 
  
    })
  }


  //login
  Login(email: string, password: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
 return this.http.post(this.baseurl + 'app_login', formData)

  }


    //user_register
    user_register(body): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('firstname', body.value.firstname);
      formData.append('lastname', body.value.lastname);
      formData.append('phone', body.value.phone);
      formData.append('email',  body.value.email);
      formData.append('password',  body.value.password);
      formData.append('password_confirmation',  body.value.password_confirmation);

     return this.http.post(this.baseurl + 'user_register', formData)
    }


    HomeData(): Observable<any> {
  
      return this.http.get(this.baseurl + 'HomeData',this.httpOptions);
    }


    
     //insert_post
     insert_post(text: string, image:File): Observable<any> {
       const formData: FormData = new FormData();
       if(image!=null){
        formData.append('image', image, image.name);
  
      }
  
      formData.append('text', text);
   
       return this.http.post(this.baseurl + 'Posts/insert_post', formData,this.httpOptions)
        }



        make_favorite(post_id): Observable<any> {
  
          return this.http.get(this.baseurl + 'Posts/make_favorite?post_id='+post_id,this.httpOptions);
        }

        
        like_post(post_id): Observable<any> {
  
          return this.http.get(this.baseurl + 'Posts/like_post?post_id='+post_id,this.httpOptions);
        }
           //add_comment
           add_comment(post_id: string, comment:string): Observable<any> {
      const formData: FormData = new FormData();
  
      formData.append('post_id', post_id);

     formData.append('comment', comment);
  
      return this.http.post(this.baseurl + 'Posts/add_comment', formData,this.httpOptions)
       }


       post_ById(post_id): Observable<any> {
  
        return this.http.get(this.baseurl + 'post_ById?post_id='+post_id,this.httpOptions);
      }
 

        //Writer_register
        Writer_register(body,cv:File,consultation): Observable<any> {
      const formData: FormData = new FormData();
      if(cv!=null){
        formData.append('cv', cv, cv.name);
  
      }
      formData.append('firstname', body.value.firstname);
      formData.append('lastname', body.value.lastname);
      formData.append('phone', body.value.phone);
      formData.append('email',  body.value.email);
      formData.append('password',  body.value.password);
      formData.append('password_confirmation',  body.value.password_confirmation);
      formData.append('category_id',  body.value.category_id);
      formData.append('faculty',  body.value.faculty);
      formData.append('university',  body.value.university);
      formData.append('faculty_from',  body.value.faculty_from);
      formData.append('faculty_to',  body.value.faculty_to);
      formData.append('consultation',  consultation);
      formData.append('consultant_money',  body.value.consultant_money);

     return this.http.post(this.baseurl + 'Writer_register', formData)
    }


    add_certification(formValue,userid) {
      const formData = {'certificate': formValue.certificate};
      console.log('ff',formValue)

      return this.http.post(this.baseurl + 'AddWriter_certification'+userid, formData);
    }

    all_category(): Observable<any> {
  
      return this.http.get(this.baseurl + 'all_category');
    }

    reshare_post(post_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'Posts/reshare_post?post_id='+post_id,  this.httpOptions);
    }

    delete_post(post_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'Posts/delete_post?post_id='+post_id,  this.httpOptions);
    }

    Auth_logout(): Observable<any> {
  
      return this.http.get(this.baseurl + 'Auth_logout',  this.httpOptions);
    }


    follow_users(friend_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'follow_users?friend_id='+friend_id,this.httpOptions);
    }
    follow_NewUsers(friend_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'follow_NewUsers?friend_id='+friend_id,this.httpOptions);
    }

    

    writers_category(): Observable<any> {
  
      return this.http.get(this.baseurl + 'writers_category',this.httpOptions);
    }
    user_posts(user_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'Posts/user_posts?user_id='+user_id,this.httpOptions);
    }

    user_follower(user_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'user_follower?user_id='+user_id,this.httpOptions);
    }

    update_userData( fileToUpload:File,firstname:string,lastname:string,phone:string,email:string): Observable<any> {
      const formData: FormData = new FormData();
      if(fileToUpload!=null){
        formData.append('image', fileToUpload, fileToUpload.name);
  
      }
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('phone', phone);
      formData.append('email', email);
      return this.http.post(this.baseurl + 'update_userData', formData,this.httpOptions)
  
    }

    show_userBy(user_id): Observable<any> {
  
      return this.http.get(this.baseurl + 'show_user_ById?user_id='+user_id,this.httpOptions);
    }
    all_UserFavorites(): Observable<any> {
  
      return this.http.get(this.baseurl + 'Posts/all_UserFavorites',this.httpOptions);
    }
    
    edit_post(image:File,text:string ,post_id): Observable<any> {
      const formData: FormData = new FormData();
      if(image!=null){
        formData.append('image', image, image.name);
      }
   formData.append('text', text);
   formData.append('post_id', post_id);

      return this.http.post(this.baseurl + 'Posts/edit_post', formData,this.httpOptions)
  
    }


    
    Text_posts(): Observable<any> {
  
      return this.http.get(this.baseurl + 'Text_posts',this.httpOptions);
    }
    textPosts_ByCategory(): Observable<any> {
  
      return this.http.get(this.baseurl + 'textPosts_ByCategory',this.httpOptions);
    }


    update_writerData( fileToUpload:File,firstname:string,lastname:string,phone:string,email:string,consultation:string,consultant_money:string): Observable<any> {
      const formData: FormData = new FormData();
      if(fileToUpload!=null){
        formData.append('image', fileToUpload, fileToUpload.name);
  
      }
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('consultation', consultation);
      formData.append('consultant_money', consultant_money);
      return this.http.post(this.baseurl + 'update_writerData', formData,this.httpOptions)
  
    }

        //certify_id
        update_certificate(certify_id: string, text:string): Observable<any> {
          const formData: FormData = new FormData();
      
          formData.append('certify_id', certify_id);
    
         formData.append('text', text);
      
          return this.http.post(this.baseurl + 'update_certificate', formData,this.httpOptions)
           }


           Video_page(): Observable<any> {
  
            return this.http.get(this.baseurl + 'Video_page',this.httpOptions);
          }

          all_videos(): Observable<any> {
  
            return this.http.get(this.baseurl + 'all_videos',this.httpOptions);
          }

              Images_page(): Observable<any> {
  
            return this.http.get(this.baseurl + 'Images_page',this.httpOptions);
          }

           all_images(): Observable<any> {
  
            return this.http.get(this.baseurl + 'all_images',this.httpOptions);
          }

          //forget_password
          forget_password(phone: string, password: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('phone', phone);
    formData.append('password', password);
 return this.http.post(this.baseurl + 'forget_password', formData)

  }
}
