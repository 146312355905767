<app-basic></app-basic>

   <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="lg:flex ">

                <div class="lg:w-4/4 content-blog test">
                    



                    <div class="divide-y">
                        <div dir="rtl">
                            <tabset >
                              <tab *ngFor="let s of blogs" heading="{{s.category_name}}" class="divide-y">
                                <div class="lg:flex lg:space-x-6 py-6 ng-star-inserted" *ngFor="let s of s.posts ">
                                    <a [routerLink]="['/blodread',s.id]"  *ngIf="s.image!=null">
                                        <div  class="lg:w-60 w-full h-40 overflow-hidden rounded-lg relative shadow-sm"> 
                                             <img  src="{{s.image}}" alt="" class="w-full h-full absolute inset-0 object-cover">
                                        </div>
                                    </a>
                                 
                                        <div *ngIf="s.video!=null" class="lg:w-60 w-full h-40 overflow-hidden rounded-lg relative shadow-sm"> 
                                            <video  controls  class="w-full h-full absolute inset-0 object-cover">
                                                <source src="{{s.video}}" type="video/mp4">
                                              
                                                
                                              </video>
                                        </div>
                                 
        
                                    <div class="flex-1 lg:pt-0 pt-4"> 
                                        <a [routerLink]="['/blodread',s.id]" *ngIf="s.file!=null"  class="text-xl font-semibold line-clamp-2">{{s.file}} </a>
                                        <a style="color: #495057;cursor: pointer;" [routerLink]="['/blodread',s.id]">
        
                                        <p class="line-clamp-2 pt-1" *ngIf="s.text!=null">{{s.text}}</p>
                                    </a>
                                      
            
                                    </div>

                                  
                                </div>
     
                            

                              </tab>
                            <!--   <div class="flex justify-center mt-6">
                                <pagination-controls (pageChange)="p = $event"  (click)="scrollToTop()"></pagination-controls>
                                 
                              </div> -->
                            </tabset>
                          </div>
                                  
                       

                     
                    </div>
                
                </div>
               <!--  <div class="lg:w-1/4 w-full flex-shrink-0 content-blog ">


                        <h3 class="text-xl font-semibold"> جهات الاتصال </h3>
          
                        <div class="" uk-sticky="offset:80">
  
                
          
                            <nav class="cd-secondary-nav border-b extanded mb-2">
                                <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                                    <li class="uk-active"><a class="active" href="#0">  المتابعين <span>{{followblogs?.followers_num}}</span> </a></li>
                                    <li><a href="#0" *ngIf="role!='2' || token== 'null'">التابعون  <span>{{followblogs?.following_num}}</span> </a></li>
                                </ul>
      
                            
                            </nav>
        
                       
                
                            <div class="contact-list uk-switcher userclienttt" style="display: flex;flex-direction: column;" id="group-details" >
      
                           
                              <div>
                               
                             <a *ngFor="let s of followers | slice:0:5">
                                             <a  [routerLink]="['/timeline',s.id]" > 
                                                <div class="contact-avatar">
                                          <img src="{{s.image}}" alt="">
                                
                                      </div></a>
                                      <div class="contact-username">   <a  [routerLink]="['/timeline',s.id]" > {{s.firstname}} {{s.lastname}}</a></div>
                                      <a (click)="followit(s.id)" *ngIf="s.is_follow==false && s.cat_name!=null" style="cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                        تابع
                                      </a>
        
                                      <a (click)="followit(s.id)" *ngIf="s.is_follow==true && s.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                          متابعة
                                      </a>
                                  </a>
                              
                            <a  [routerLink]="'/Allfollowers'" class=" bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize mt-2"  *ngIf="followers?.length>5"> 
                                عرض الكل
                            </a>
                              </div>
                              <div>
                          
                                    <a *ngFor="let s of following | slice:0:5">
                                              <a  [routerLink]="['/timeline',s.id]" >     <div class="contact-avatar">
                                            <img src="{{s.image}}" alt="">
                                  
                                        </div></a>
                                        <div class="contact-username">     
                                                 <a  [routerLink]="['/timeline',s.id]" > {{s.firstname}} {{s.lastname}}</a></div>
                                        <a (click)="followit(s.id)" *ngIf="s.is_follow==false && s.cat_name!=null" style="cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                          تابع
                                        </a>
          
                                        <a (click)="followit(s.id)" *ngIf="s.is_follow==true && s.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                            متابعة
                                        </a>
                                    </a>
                               
                               
                               
                                 
                                    <a  [routerLink]="'/Allfollowing'" class=" bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize mt-2" *ngIf="following?.length>5"> 
                                        عرض الكل
                                    </a>
                                
                              
                             
                             
                               
                              </div>
                              
                
                           
                            </div>
      
                          
                
                        
                
                        </div>
            
                </div> -->
            </div>

    
        </div>
    </div>
    


       <!-- open chat box -->
       <div uk-toggle="target: #offcanvas-chat" class="start-chat">
        <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
        </svg>
    </div>
    
    <div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true">
        <div class="uk-offcanvas-bar bg-white p-0 w-full lg:w-80">
    
    
            <div class="relative pt-5 px-4">
    
                <h3 class="text-2xl font-bold mb-2"> الدردشات </h3>
    
                <div class="absolute right-3 top-4 flex items-center">
    
                    <button class="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                        type="button" uk-close></button>
    
                    <a href="#" uk-toggle="target: #search;animation: uk-animation-slide-top-small">
                       
                    </a>
                    <a href="#">
 
                    </a>
                    <a href="#">
                     
                    </a>
    
                </div>
    
    
            </div>
    
            <div class="absolute bg-white z-10 w-full -mt-5 lg:mt-0 transform translate-y-1.5 py-2 border-b items-center flex"
                id="search" hidden>
                <input type="text" placeholder="Search.." class="flex-1">

            </div>
    
            <nav class="cd-secondary-nav border-b extanded mb-2">
                <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
                    <li class="uk-active"><a class="active" href="#0"> أصدقاء </a></li>
                    <li><a href="#0">مجموعات <span> 10 </span> </a></li>
                </ul>
            </nav>
    
            <div class="contact-list px-2 uk-switcher" id="chats-tab">
    
                <div>
    
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-1.jpg" alt="">
      
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-2.jpg" alt="">
                            <span class="user_status"></span>
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-3.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-4.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
    
                </div>
                <div>
    
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-1.jpg" alt="">
                      
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-2.jpg" alt="">
                            <span class="user_status"></span>
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-3.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-4.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
    
                </div>
    
            </div>
        </div>
    </div>
    <ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>