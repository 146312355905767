<div data-layout="horizontal" data-topbar="colored" class="bg-body">
<div class="account-pages">
    <div class="container">
      
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-6">
                <div class="card card-login card-login1">
                    <a href="index.html" class="auth-logo">
                        <img src="assets/images/logoo.png" alt="" class="logo1">
                    </a>
                    <div class="card-body"> 
                        <div class="text-center">
                            <h5>عمل حساب جديد</h5>
                        </div>
                        <div class="p-2 mt-4">
                            <form  [formGroup]="userRegistration" (ngSubmit)="submitForm(userRegistration)">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group position-relative">
                                            <input type="text" class="form-control" formControlName="firstname" name="firstname"  placeholder="الاسم الأول">
                                            <img class="icon-input" src="assets/images/user-register.png">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group position-relative">
                                            <input type="text" class="form-control" name="lastname" formControlName="lastname" placeholder="الاسم الثاني">
                                            <img class="icon-input" src="assets/images/user-register.png">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group position-relative">
                                            <input type="email" class="form-control" name="email" formControlName="email" placeholder="البريد الالكترونى">
                                            <img class="icon-input" src="assets/images/email.png">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group position-relative">
                                            <input type="text" class="form-control" name="phone" formControlName="phone" placeholder="رقم الهاتف">
                                            <img class="icon-input" src="assets/images/phone-register.png">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group position-relative">
                                            <input type="password" class="form-control" name="password" formControlName="password" placeholder="كلمة السر">
                                            <img class="icon-input" src="assets/images/lock.png">
                                        </div>
                                    </div>
                                      <div class="col-xl-6">
                                        <div class="form-group position-relative">
                                            <input type="password" class="form-control" name="password_confirmation" formControlName="password_confirmation" placeholder="تاكيد كلمة السر">
                                            <img class="icon-input" src="assets/images/lock.png">
                                        </div>
                                    </div>
                                </div>

                            
        
                             
        
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"  class="custom-control-input" id="auth-remember-check" name="authremembercheck" formControlName="authremembercheck">
                                    <label class="custom-control-label" for="auth-remember-check">الموافقة على الشروط والاحكام</label>
                                </div>
                                
                                <div class="mt-3 text-center mb-10">
                                    <button class="btn btn-primary w-sm waves-effect waves-light" type="submit">عمل حساب جديد</button>
                                </div>
    

                                <div class="text-center">
                                    <p class="mb-0"><a  [routerLink]="'/login'" class="btn-new-account"> هل تمتلك حساب ؟ </a> </p>
                                </div>
                            </form>
                        </div>
    
                    </div>
                </div>

            

            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
</div>
<ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>