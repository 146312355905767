import { Component, OnInit , Input, ViewChild} from '@angular/core';
import {RouterModule} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;
declare var $: any;

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.css']
})
export class BasicComponent implements OnInit {
  firstname= localStorage.getItem('firstname');
  lastname= localStorage.getItem('lastname');
  image= localStorage.getItem('image');
 token= localStorage.getItem('Token');
 UserID= localStorage.getItem('UserID');

 data=localStorage.getItem('res');


    constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService) { }
    profile()
    {
      this.router.navigate(['/timeline',this.UserID]).then(() => {
        window.location.reload();
      }); 
    }
  ngOnInit(): void {
    console.log("UserID",this.UserID)

    console.log("fn",this.token)
  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("display", "none")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("display", "none")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("display", "none")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }
  logout()
  {
    localStorage.clear();

    this.router.navigate(['/login'])

    this._service.Auth_logout()
    .subscribe(); 
    window.location.reload();
    this.ngOnInit()

  }
}
