import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  loading:boolean=false
    
  userRegistration = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    password_confirmation: new FormControl('', Validators.required),
    authremembercheck: new FormControl(false, [Validators.requiredTrue]),
}, ) 
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router) {}

  ngOnInit(): void {
    this.spinner.show();

  }


  submitForm(fromDate){
    this.loading=true

console.log(fromDate.value.authremembercheck)
if(fromDate.valid)
{

   this._service.user_register(fromDate).subscribe(response =>{
    let error:number= response["error"];
    let message:string= response["message"];

    if(error==0){
     
   
          Swal.fire({
            icon: 'success',
            title: message,
            showConfirmButton: false,
            timer: 2000

          })
          this.loading=false
          let res:any=response["data"];
               let Token:string= res["token"];
               let UserID:number= res["id"];
               let firstname:string= res["firstname"];
               let lastname:string= res["lastname"];
               let Phone:string= res["phone"];
               let Email:string= res["email"];
               let image:string= res["image"];
               let role:string= res["role"];
               let state:string= res["state"];
   
   
               localStorage.setItem('res',res);
   
               localStorage.setItem('Token',Token);
               localStorage.setItem('UserID',UserID.toString());
               localStorage.setItem('firstname',firstname);
               localStorage.setItem('lastname',lastname);
   
               localStorage.setItem('Phone',Phone);
               localStorage.setItem('Email',Email);
               localStorage.setItem('image',image);
               localStorage.setItem('role',role);
               localStorage.setItem('state',state);
             this.router.navigate(['/Home']).then(() => {
              window.location.reload();
            });
    
    
      }

    else
    {
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 2000

      })
    }
    this.loading=false;}) 

}


else if(fromDate.value.authremembercheck==false)
{
  Swal.fire('من فضلك وافق ع الشروط والاحكام')

 
  this.loading=false


}


else{
  Swal.fire('برجاء ملئ الحقول بشكل صحيح ')


  this.loading=false

} 
  }
}
