import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-followersbyid',
  templateUrl: './followersbyid.component.html',
  styleUrls: ['./followersbyid.component.css']
})
export class FollowersbyidComponent implements OnInit {
  loading:boolean=true
 
  followers:any[]
  followinggggg:any[]
  users:any[]
  homedata
  token= localStorage.getItem('Token');
 role= localStorage.getItem('role');
 userid=localStorage.getItem('UserID')
 writercat:any[]
 id
 postid
 p: number = 1;
 firstname= localStorage.getItem('firstname');
 lastname= localStorage.getItem('lastname');
 image= localStorage.getItem('image');
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService,private route:ActivatedRoute) {}


  ngOnInit(): void {
    this.spinner.show();
    this.id=+this.route.snapshot.paramMap.get('id');

 
    this._service.user_follower(this.id)
    .subscribe(
      data => {
       let res: any = data["data"];
        this.followers = res;
         this.loading=false
 });


  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }
  followit(id)
  {
    this._service.follow_NewUsers(id)
    .subscribe();
    this.ngOnInit()
  }

}
