import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  loading:boolean=true
 token= localStorage.getItem('Token');
 role= localStorage.getItem('role');
 userid=localStorage.getItem('UserID')
 firstname= localStorage.getItem('firstname');
 lastname= localStorage.getItem('lastname');
 image= localStorage.getItem('image');
 blogs:any
 posts:any[];
 followers:any[]
 following:any[]
 p: number = 1;
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService) {}

  
  scrollToTop()
  {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.spinner.show();
     console.log("userid",this.userid)
    this._service.Text_posts()
     .subscribe(
       data => {
        let res: any = data["data"];
        this.blogs = res;
         let resources: any = data["data"]["posts"];
         let resources1: any = data["data"]["followers"];
         let resources2: any = data["data"]["following"];

          this.posts = resources;
          this.followers = resources1;
          this.following = resources2;
        
          this.loading=false
  });



   var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }

 
 



 

 
  followit(id)
  {
    this._service.follow_users(id)
    .subscribe(); 
    this.ngOnInit()
  }



}
