
export const environment = {
  production: false,
  apiHost: "https://writer.jaderplus.com/api/",
   firebaseConfig:{
    apiKey: "AIzaSyCRoSzKFYPqjhBYeRBjrqG6XU2vD_3RI5E",
    authDomain: "writter-40bb9.firebaseapp.com",
    databaseURL: "https://writter-40bb9-default-rtdb.firebaseio.com",
    projectId: "writter-40bb9",
    storageBucket: "writter-40bb9.appspot.com",
    messagingSenderId: "750352842154",
    appId: "1:750352842154:web:1e95021fef4a39b4da3fd3",
    measurementId: "G-E9MML7V0QM"
  }
};

