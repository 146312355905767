<div id="wrapper">

    <!-- Header -->
    <header>
        <div class="header_wrap">
            <div class="header_inner mcontainer">
                <div class="left_side">
                    
                    <span class="slide_menu" uk-toggle="target: #wrapper ; cls: is-collapse is-active">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="currentColor"></path></svg>
                    </span>
  
                    <div id="logo">
                        <a  [routerLink]="'/Home'"> 
                            <img src="assets/images/logo.png" alt="">
                            <img src="assets/images/logo-mobile.png" class="logo_mobile" alt="">
                        </a>
                    </div>
                </div>
                 
                <!-- search icon for mobile -->
                <div class="header-search-icon" uk-toggle="target: #wrapper ; cls: show-searchbox"> </div>
                         
                <div class="right_side">
                    <div class="header_widgets loginbtn"  >
                        <a   [routerLink]="'/login'" class="is_link">  تسجيل دخول  </a> 
                        <a   [routerLink]="'/usertype'" class="is_link register">انشاء حساب؟</a>  
                        </div>  
                    <div class="header_widgets userclienttt">
                        <a  [routerLink]="'/Home'" class="is_link">  الرئيسية  </a> 
                      

                        <a (click)="profile()" style="cursor: pointer;" class="is_link">{{firstname}} {{lastname}} </a>  
                     
                       
  <!-- 
                        <a class="is_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                            </svg>
                            <span>3</span>
                        </a>
                        <div uk-drop="mode: click" class="header_dropdown">
                             <div  class="dropdown_scrollbar" data-simplebar>
                                 <div class="drop_headline">
                                     <h4>إشعارات </h4>
                                     <div class="btn_action">
                                         <a href="#">
                                             <i class="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left" title="" aria-expanded="false"></i>
                                         </a>
                                         <a href="#">
                                             <i class="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left" title="" aria-expanded="false"></i>
                                         </a>
                                     </div>
                                 </div>
                                 <ul>
                                     <li>
                                         <a href="#">
                                             <div class="drop_avatar"> 
                                                 <img src="assets/images/avatars/avatar-1.jpg" alt="">
                                             </div>
                                             <span class="drop_icon bg-gradient-primary">
                                                 <i class="icon-feather-thumbs-up"></i>
                                             </span>
                                             <div class="drop_text">
                                                 <p>
                                                    <strong>أحمد علي</strong> مثل تعليقك على الفيديو
                                                    <span class="text-link">تعلم النموذج الأولي بشكل أسرع </span>
                                                 </p>
                                                 <time> منذ 2 ساعات </time>
                                             </div>
                                         </a>
                                     </li>
                                     <li class="not-read">
                                         <a >
                                             <div class="drop_avatar status-online"> <img src="assets/images/avatars/avatar-2.jpg" alt="">
                                             </div>
                                             <div class="drop_text">
                                                 <p>
                                                    <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                    <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                 </p>
                                                 <time> منذ 9 ساعات </time>
                                             </div>
                                         </a>
                                     </li>
                                     <li>
                                         <a >
                                             <div class="drop_avatar"> <img src="assets/images/avatars/avatar-3.jpg" alt="">
                                             </div>
                                             <span class="drop_icon bg-gradient-primary">
                                                <i class="icon-feather-thumbs-up"></i>
                                            </span>
                                            <div class="drop_text">
                                                <p>
                                                   <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                   <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                </p>
                                                <time> منذ 9 ساعات </time>
                                            </div>
                                         </a>
                                     </li>
                                     <li>
                                         <a href="#">
                                             <div class="drop_avatar"> <img src="assets/images/avatars/avatar-1.jpg" alt="">
                                             </div>
                                             <div class="drop_text">
                                                <p>
                                                   <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                   <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                </p>
                                                <time> منذ 9 ساعات </time>
                                            </div>
                                         </a>
                                     </li>
                                     <li>
                                         <a href="#">
                                             <div class="drop_avatar"> <img src="assets/images/avatars/avatar-1.jpg" alt="">
                                             </div>
                                             <span class="drop_icon bg-gradient-primary">
                                                <i class="icon-feather-thumbs-up"></i>
                                            </span>
                                            <div class="drop_text">
                                                <p>
                                                   <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                   <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                </p>
                                                <time> منذ 9 ساعات </time>
                                            </div>
                                         </a>
                                     </li>
                                     <li>
                                         <a href="#">
                                             <div class="drop_avatar status-online"> <img src="assets/images/avatars/avatar-2.jpg" alt="">
                                             </div>
                                             <div class="drop_text">
                                                <p>
                                                   <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                   <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                </p>
                                                <time> منذ 9 ساعات </time>
                                            </div>
                                         </a>
                                     </li>
                                     <li>
                                         <a href="#">
                                             <div class="drop_avatar"> <img src="assets/images/avatars/avatar-2.jpg" alt="">
                                             </div>
                                             <div class="drop_text">
                                                <p>
                                                   <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                   <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                </p>
                                                <time> منذ 9 ساعات </time>
                                            </div>
                                         </a>
                                     </li>
                                     <li>
                                         <a href="#">
                                             <div class="drop_avatar"> <img src="assets/images/avatars/avatar-3.jpg" alt="">
                                             </div>
                                             <div class="drop_text">
                                                <p>
                                                   <strong>أحمد علي</strong> أعد تشغيل تعليقاتك بتنسيق
                                                   <span class="text-link">برنامج Adobe XD التعليمي</span>
                                                </p>
                                                <time> منذ 9 ساعات </time>
                                            </div>
                                         </a>
                                     </li>
                                  
                                 </ul> 
                             </div>
                        </div> --> 
  
                        <!-- Message -->
                      <!--   <a href="#" class="is_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                            </svg>
                            <span>4</span>
                        </a>
                        <div uk-drop="mode: click" class="header_dropdown is_message">
                            <div  class="dropdown_scrollbar" data-simplebar>
                                <div class="drop_headline">
                                    <h4>الرسائل </h4>
                                    <div class="btn_action">
                                        <a href="#">
                                            <i class="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left" title="" aria-expanded="false"></i>
                                        </a>
                                        <a href="#">
                                            <i class="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left" title="" aria-expanded="false"></i>
                                        </a>
                                    </div>
                                </div>
                                <input type="text" class="uk-input" placeholder="ابحث في الرسائل">
                                <ul>
                                    <li class="un-read">
                                        <a href="#">
                                            <div class="drop_avatar"> <img src="assets/images/avatars/avatar-7.jpg" alt="">
                                            </div>
                                            <div class="drop_text">
                                                <strong> محمد علي </strong> <time>12:43 مساءً</time>
                                                <p>  سوف يشرح لك اليكس كيف ...  </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div class="drop_avatar"> <img src="assets/images/avatars/avatar-1.jpg" alt="">
                                            </div>
                                            <div class="drop_text">
                                                <strong> محمد علي </strong> <time>12:43 مساءً</time>
                                                <p>  سوف يشرح لك اليكس كيف ...  </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div class="drop_avatar"> <img src="assets/images/avatars/avatar-6.jpg" alt="">
                                            </div>
                                            <div class="drop_text">
                                                <strong> محمد علي </strong> <time>12:43 مساءً</time>
                                                <p>  سوف يشرح لك اليكس كيف ...  </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div class="drop_avatar"> <img src="assets/images/avatars/avatar-5.jpg" alt="">
                                            </div>
                                            <div class="drop_text">
                                                <strong> محمد علي </strong> <time>12:43 مساءً</time>
                                                <p>  سوف يشرح لك اليكس كيف ...  </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="un-read">
                                        <a href="#">
                                            <div class="drop_avatar"> <img src="assets/images/avatars/avatar-2.jpg" alt="">
                                            </div>
                                            <div class="drop_text">
                                                <strong> محمد علي </strong> <time>12:43 مساءً</time>
                                                <p>  سوف يشرح لك اليكس كيف ...  </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div class="drop_avatar"> <img src="assets/images/avatars/avatar-1.jpg" alt="">
                                            </div>
                                            <div class="drop_text">
                                                <strong> محمد علي </strong> <time>12:43 مساءً</time>
                                                <p>  سوف يشرح لك اليكس كيف ...  </p>
                                            </div>
                                        </a>
                                    </li>
                                
                                </ul>
                            </div>
                            <a href="#" class="see-all"> مشاهدة الكل في الرسائل</a>
                        </div>
     
     -->
                        <a  style="cursor: pointer;" >
                            <img src="{{image}}" class="is_avatar" alt="">
                        </a>
                        <div uk-drop="mode: click;offset:5" class="header_dropdown profile_dropdown">
  
                            <a  [routerLink]="['/timeline',UserID]"  class="user" style="cursor: pointer;">
                                <div class="user_avatar">
                                    <img src="{{image}}" alt="">
                                </div>
                                <div class="user_name">
                                    <div>{{firstname}} {{lastname}}</div>
                                    <!-- <span> @stella </span> -->
                                </div>
                          </a>
                            <hr class="border-gray-100">
                            <a  (click)="profile()" style="cursor: pointer;">
                                <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                                حسابي 
                            </a>
                          
                           
                         
                            <a (click)="logout()" style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                </svg>
                                خروج
                            </a>
  
                            
                        </div>
  
                    </div>

            
                </div>
            </div>
        </div>
    </header>
      <!-- sidebar -->
      <div class="sidebar">
        <div class="sidebar_header"> 
            <img src="assets/images/logo.png" alt="">
            <img src="assets/images/logo-icon.html" class="logo-icon" alt="">
  
            <span class="btn-mobile" uk-toggle="target: #wrapper ; cls: is-collapse is-active"></span>
  
        </div>
    
        <div class="sidebar_inner" data-simplebar>
    
            <ul>
                <li [routerLinkActive]="['active']"><a  [routerLink]="'/Home'"> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-600"> 
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                    <span> جديد </span> </a> 
                </li>
      <li [routerLinkActive]="['active']"><a  [routerLink]="'/Blogs'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-red-500">
                        <path fill-rule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clip-rule="evenodd" />
                        <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                    </svg>
                    <span> المقالات</span></a> 
                </li> 
                <li [routerLinkActive]="['active']"><a  [routerLink]="'/writercat'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                        <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                        <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                    </svg>
                    <span> الكتاب</span></a> 
                </li> 
                <li [routerLinkActive]="['active']"><a  [routerLink]="'/DeptBlogs'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-yellow-500">
                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path>
                      </svg>
                    <span> الأقسام</span></a> 
                </li> 
                <li [routerLinkActive]="['active']"><a  [routerLink]="'/favs'">
                    <svg style="color:#ef4444" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 mr-1.5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                        </path>
                    </svg>
                    <span> المفضلات</span></a> 
                </li> 
                <li [routerLinkActive]="['active']"><a  [routerLink]="'/Videos'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-red-500">
                        <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm3 2h6v4H7V5zm8 8v2h1v-2h-1zm-2-2H7v4h6v-4zm2 0h1V9h-1v2zm1-4V5h-1v2h1zM5 5v2H4V5h1zm0 4H4v2h1V9zm-1 4h1v2H4v-2z" clip-rule="evenodd" />
                    </svg>
                    <span> الفيديو</span></a> 
                </li> 
               
                <li [routerLinkActive]="['active']"><a  [routerLink]="'/videoshome'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-red-500">
                        <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm3 2h6v4H7V5zm8 8v2h1v-2h-1zm-2-2H7v4h6v-4zm2 0h1V9h-1v2zm1-4V5h-1v2h1zM5 5v2H4V5h1zm0 4H4v2h1V9zm-1 4h1v2H4v-2z" clip-rule="evenodd" />
                    </svg>
                    <span> شاهد عن الحدث </span></a> 
                </li> 
                <li hidden [routerLinkActive]="['active']" id="more-veiw" ><a  [routerLink]="'/WatchEvent'"> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                        <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
                    </svg>  <span> الصور </span></a> 
                </li>
                <li hidden [routerLinkActive]="['active']" id="more-veiw" ><a  [routerLink]="'/imageshome'"> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                        <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
                    </svg>  <span> خبر في الصورة </span></a> 
                </li>  
          
            </ul>
  
  
            <li class="active-submenu" hidden>
                <a href="#"> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-pink-500">
                        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                    </svg>  <span>  ألعاب </span>
                </a> 
                <ul>
                    <li><a href="dashboard-manage-jobs.html">Manage Jobs <span class="nav-tag">3</span></a></li>
                    <li><a href="dashboard-manage-candidates.html">Manage Candidates</a></li>
                    <li><a href="dashboard-post-a-job.html">Post a Job</a></li>
                </ul>
            </li>
  
            <a href="#" class="see-mover h-10 flex my-1 pl-2 rounded-xl text-gray-600" uk-toggle="target: #more-veiw; animation: uk-animation-fade"> 
                <span class="w-full flex items-center" id="more-veiw">
                    <svg class="  bg-gray-100 mr-2 p-0.5 rounded-full text-lg w-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    شاهد المزيد  
                </span>
                <span class="w-full flex items-center" id="more-veiw" hidden>
                    <svg  class="bg-gray-100 mr-2 p-0.5 rounded-full text-lg w-7"  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg> 
                    رؤية أقل 
                </span>
            </a> 
  
            <hr>
  
            
         
            
  
        </div>
    
</div>
</div> 