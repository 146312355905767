import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-allfollowers',
  templateUrl: './allfollowers.component.html',
  styleUrls: ['./allfollowers.component.css']
})
export class AllfollowersComponent implements OnInit {
  loading:boolean=true
 
  followers:any[]
  followinggggg:any[]
  users:any[]
  homedata
  token= localStorage.getItem('Token');
 role= localStorage.getItem('role');
 userid=localStorage.getItem('UserID')
 writercat:any[]

 postid
 p: number = 1;
 firstname= localStorage.getItem('firstname');
 lastname= localStorage.getItem('lastname');
 image= localStorage.getItem('image');
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService) {}


  ngOnInit(): void {
    this.spinner.show();

    this._service.HomeData()
     .subscribe(
       data => {
        let res: any = data["data"];


         let resources2: any = data["data"]["followers"];
         let resources3: any = data["data"]["following"];
         this.homedata = res;

          this.followers = resources2;
          this.followinggggg = resources3;
          this.loading=false
  });


  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }
  followit(id)
  {
    this._service.follow_NewUsers(id)
    .subscribe();
    this.ngOnInit()
  }

}
