<div  data-layout="horizontal" data-topbar="colored" class="bg-body">
<div class="account-pages">
    <div class="container">
      
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-6">
                <div class="card card-login card-login3">
                    <a class="auth-logo">
                        <img src="assets/images/logo.png" alt="" class="logo1">
                    </a>
                    <div class="card-body"> 
                        <div class="text-center">
                            <h5>عمل حساب جديد</h5>
                        </div>
                        <div class="p-2 mt-4">


                            <form  [formGroup]="writerRegistration" (ngSubmit)="submitForm(writerRegistration)" >
 
       
                                       <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="form-group position-relative">
                                                        <input type="text" class="form-control"  formControlName="firstname" name="firstname" id="fullname" placeholder="الاسم بالكامل">
                                                        <img class="icon-input" src="assets/images/user-register.png">
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div class="form-group position-relative">
                                                        <input type="text" class="form-control" formControlName="lastname" name="lastname" id="username" placeholder="اسم الاخير">
                                                        <img class="icon-input" src="assets/images/user-register.png">
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div class="form-group position-relative">
                                                        <input type="email" class="form-control" formControlName="email" name="email" id="email" placeholder="البريد الالكترونى">
                                                        <img class="icon-input" src="assets/images/email.png">
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div class="form-group position-relative">
                                                        <input type="text" class="form-control" id="phone" formControlName="phone" name="phone" placeholder="رقم الهاتف">
                                                        <img class="icon-input" src="assets/images/phone-register.png">
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div class="form-group position-relative">
                                                        <input type="password" class="form-control" id="password" formControlName="password" name="password" placeholder="كلمة السر">
                                                        <img class="icon-input" src="assets/images/lock.png">
                                                    </div>
                                                </div>
                                                  <div class="col-xl-6">
                                                    <div class="form-group position-relative">
                                                        <input type="password" class="form-control" id="cpassword" formControlName="password_confirmation" name="password_confirmation" placeholder="تاكيد كلمة السر">
                                                        <img class="icon-input" src="assets/images/lock.png">
                                                    </div>
                                                </div>
                                         
                                                <!-- <div class="col-xl-12 custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="auth-remember-check">
                                                    <label class="custom-control-label" for="auth-remember-check">الموافقة على الشروط والاحكام</label>
                                                </div> -->
                                                
                                              <!--   <div class="mt-3 text-left mb-10 button">
                                                    <button cdkStepperNext class="btn button btn-primary w-sm waves-effect waves-light" type="button" >التالي</button>
                                                </div>
                     -->
                                        
                                   
                                     
                                      
                                        
                         
                                    
                                      
                                    <!-- </cdk-step>
                                    
                                    <cdk-step [stepControl]="stepTwoForm" [optional]="true">
                                      <ng-template cdkStepLabel>
                                        <div class="step-bullet">2</div>
                                        <div class="step-title">الخطوة الثانية</div>
                                      </ng-template>
                                      <div class="container">
                                          <div class="row"> -->
                                            <div class="col-xl-6">
                                                <div class="form-group position-relative">
                                                    <input formControlName="faculty" name="faculty" type="text" class="form-control"  placeholder="الكلية">
                                                    <img class="icon-input" src="assets/images/user-register.png">
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="form-group position-relative">
                                                    <input type="text" formControlName="university" name="university" class="form-control" placeholder="الجامعة">
                                                    <img class="icon-input" src="assets/images/user-register.png">
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="form-group position-relative">
                                                    <label for="date-at">من</label>
                                                    <input type="date" class="form-control" formControlName="faculty_from" name="faculty_from" id="email" placeholder="من">
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="form-group position-relative">
                                                    <label for="date-at">الي</label>
                                                    <input type="date" class="form-control" id="phone" formControlName="faculty_to" name="faculty_to" placeholder="الي">
                                                </div>
                                            </div>
                                            <div class="col-xl-12">
                                                <div class="form-group position-relative">
                                                    <select  class="form-control" formControlName="category_id" name="category_id" >
                                                        <option hidden value="" disabled selected>أختر مجال الكتابة</option>

                                                        <option value="{{s.id}}" *ngFor="let s of cats">{{s.name}}</option>
                                                      
                                                    </select>
                                                    <img class="icon-input" src="assets/images/lock.png">
                                                </div>
                                            </div>
                                        
                                            <div class="col-xl-12">
                                                <div class="button-wrapper-file">
                                                    <span class="label">
                                                      <img src="assets/images/file-white.png">ارفاق الملفات
                                                    </span>
                                                    
                                                      <input  name="upload" accept="/*" type="file" (change)="handlefileInput($event.target.files)" id="upload" class="upload-box" placeholder="">
                                                    
                                                </div>
                                             
                                            </div>
               

                                 <div class="col-xl-12">


                                            <form [formGroup]="JoinUsForm" >
                                                <div class="row">
                        
                                                      
                                                    <div class="col-xl-12" formArrayName="certificate">
                                                    <div *ngFor="let certificate of certificate.controls; let i = index" style="margin-top: 10px">
                                                       
                                                        
                                                        <div [formGroupName]="i">
                                                                <div  class="input-group mb-3">

                                                                    <input type="text" formControlName="text" class="form-control m-input"
                                                                    placeholder="الشهادات والخبرات">
                                                                    <img class="icon-input" src="assets/images/medal.png">
                                                                    <div (click)="removenewcertificate(i)" id="removeRow" class="image-upload  input-group-append">
                                                                        
                                                                        <label for="file-input" style="background-color: #b82626;">-</label>
                                                                    </div>
                                                                  
                                                                    
                                                                </div>
                                                           
                                                          
                                                                   
                                                        </div>
                                                        </div>
                                                        </div>
                                                 
                                                    <button (click)='addcertificate()' style="width: 100%;background-color: #9ed9fe;color:#9ed9fe" type="button" class="btn btnceri">+ اضافة شهادة 
                                                    </button>
                                    
                                                </div>
                                                <br>
                                            </form>
                                            
                                        </div>  
                                            <div class="col-xl-12 d-flex">
                                                <div class="custom-control custom-radio custom-control-right">
                                                    <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" value="1" (change)="onconsulation($event)">
                                                    <label class="custom-control-label" for="customRadio1">الرد على الاستشارات مجانا</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-right">
                                                    <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" value="3" (change)="onconsulation($event)">
                                                    <label class="custom-control-label" for="customRadio3">بدون مراسلة</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-right">
                                                    <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" value="2" (change)="onconsulation($event)">
                                                    <label class="custom-control-label" for="customRadio2">الرد على الاستشارات بمقابل مادى</label>
                                                </div>

                                            </div>
                                            <div class="col-xl-6"></div>
                                            <div class="col-xl-6 text-left">
                                                <input class="input-monetary-value" type="number" formControlName="consultant_money" name="consultant_money" placeholder="تحديد القيمة المادية">
                                            </div>

                                       
                                  <!--      
                                    </div>
         
                              </div>
                     
                           
                            </cdk-step>

                                                      
                              
                               
                                 </ng-stepper> -->
                             
                                 <div class="text-center mt-10" style="width: 100%;">
                                    <button class="btn btn-primary w-sm waves-effect waves-light" type="submit"
                                   >عمل حساب جديد</button>
                                      <!-- center modal -->
                                      <p class="mb-0"><a  [routerLink]="'/login'" class="btn-new-account"> هل تمتلك حساب ؟ </a> </p>
    
                          
                                </div>  
                            </div>
                                </form>     
        
                             

                    
                        </div>
    
                    </div>
                </div>

            

            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
</div>