<app-basic></app-basic>
<div class="main_content">
    <div class="mcontainer">
        <div class="lg:flex lg:space-x-10">
            <div class="lg:w-3/4 lg:px-20 space-y-7">
                <div class="user_story grid lg:grid-cols-5 grid-cols-3 gap-2 lg:-mx-20 relative"  >
          
                    <a  uk-toggle="target: body ; cls: story-active"  style="cursor: pointer;" *ngFor="let s of stories| slice:0:5" >
                        <div class="single_story" (click)="story(s.post_id,s.image,s.user_image,s.firstname,s.lastname)">
                          <img src="{{s.image}}" alt="">
                            <div class="story-avatar">  <a [routerLink]="['/timeline',s.user_id]" > <img src="{{s.user_image}}" alt=""></a></div>
                            <div class="story-content"> <h4> {{s.firstname}}</h4> </div>
                        </div>
                    </a>
           
          
                </div>
    
        <div class="relative" >
        
            <div class=" px-1 py-3">
                <ul class=" uk-child-width-1-3@m uk-child-width-1-3@s uk-grid-small uk-grid lg:-mx-20" dir="rtl">
                    <li *ngFor="let s of all_images| slice:0:3">
                        <div class="card">
                            <div class="card-media h-32">
                                <div class="card-media-overly"></div>
                                <img src="{{s.image}}" alt="" class="" height="100%">
                              
                            </div>
                            <div class="card-body text-right">
                                <div class="">
                            <p class="text-sm text-right"> بواسطة <a [routerLink]="['/timeline',s.user_id]" style="color: #5b73e8;"> {{s.firstname}}  {{s.lastname}}</a> </p>
                            <div class="flex space-x-2 items-center text-xs">
                                <div>{{s.new_createdAt}}</div>
                           
                            </div>
                        </div>
            
                            
            
                            </div>
                        </div>
                    </li>
                  
                </ul>

              
            </div>
        </div>
    
        <br>
  
    
    <div class="md:mb-4 mb-3  lg:-mx-20 dir-content userclienttt">
                    <h2 class="text-2xl font-semibold"> الصور الخاص بي </h2>
                  
                </div>

      
<!-- 
                <div class="divide-y dir-content ">

                    <div class="flex md:space-x-6 space-x-4 md:py-5 py-3 relative  lg:-mx-20" *ngFor="let s of my_images">
                        <a [routerLink]="['/blodread',s.id]" class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm"> 
                             <img src="{{s.image}}" alt="" class="w-full h-full absolute inset-0 object-cover">
                        </a>
                        <a style="color: #495057;cursor: pointer;"  [routerLink]="['/blodread',s.id]">
                        <div class="flex-1 space-y-2"> 
                             
                            <p class="leading-6 pr-4 line-clamp-2 md:block hidden">{{s.text}} </p>
                            <p class="text-sm text-right"> بواسطة <a [routerLink]="['/timeline',s.user_id]" style="color: #5b73e8;"> {{s.firstname}}  {{s.lastname}} </a>  </p>

                           
                           <div class="flex items-center justify-between">
                                <div class="flex space-x-3 items-center text-sm md:pt-3">
                                    <div> {{s.new_createdAt}}</div>
                               
                                </div>
                            </div>


                        </div>
                        </a>
                    </div> 
                  

                </div> 
 -->

                <div class="divide-y lg:-mx-20 "  >
                            
                    <div class="lg:flex  py-6 " *ngFor="let s of my_images" dir="rtl">
                      
                        
                     
            
                        <div class="flex-1 pr-4 lg:pt-0 pt-4 pr-20" > 
                            <a style="color: #495057;cursor: pointer;"  [routerLink]="['/blodread',s.id]">
            
                            <p class="line-clamp-2 pt-1 text-right" *ngIf="s.text!=null">{{s.text}}</p>
                            <div class="">
                                <p class="text-sm text-right"> بواسطة <a [routerLink]="['/timeline',s.user_id]" style="color: #5b73e8;"> {{s.firstname}}  {{s.lastname}} </a>  </p>
                                <div class="flex space-x-2 items-center text-xs  text-right">
                                    <div class="text-right">{{s.new_createdAt}}</div>
                               
                                </div>
                            </div>
                        </a>
                          
            
                        </div>
                        <div  class="lg:w-60 w-full h-40 overflow-hidden rounded-lg relative shadow-sm"> 
                           

                              <a [routerLink]="['/blodread',s.id]" > 
                                <img src="{{s.image}}" alt="" class="w-full h-full absolute inset-0 object-cover">
                           </a>
                        </div>
                    </div>
            
                 
                </div>

</div>

                <div class="lg:w-72 w-full">
          
          
                    <h3 class="text-xl font-semibold"> جهات الاتصال </h3>
                
                    <div class="" uk-sticky="offset:80">
                
                  
                
                        <nav class="cd-secondary-nav border-b extanded mb-2">
                            <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                                <li class="uk-active"><a class="active" href="#0">  المتابعين <span>{{homedata?.followers_num}}</span> </a></li>
                                <li><a href="#0" *ngIf="role!='2' || token== 'null'">التابعون  <span>{{homedata?.following_num}}</span> </a></li>
                            </ul>
                
                         
                        </nav>
                
                   
                
                        <div class="contact-list uk-switcher userclienttt" id="group-details" >
                
                       
                          <div >
                           
                         <a *ngFor="let s of followers | slice:0:5">
                                 <a  [routerLink]="['/timeline',s.id]" >    <div class="contact-avatar">
                                    
                                <img src="{{s.image}}" alt="">
                           
                                  </div></a>
                                  <div class="contact-username"><a  [routerLink]="['/timeline',s.id]"  style="color:#2d2d2d;cursor:pointer">{{s.firstname}} {{s.lastname}}</a></div>
                                  <a (click)="followit(s.id)" *ngIf="s.is_follow==false && s.cat_name!=null" style="cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                    تابع
                                  </a>
                
                                  <a (click)="followit(s.id)" *ngIf="s.is_follow==true && s.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                      متابعة
                                  </a>
                              </a>
                          
                              <a  [routerLink]="'/Allfollowers'" class=" bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize mt-2"  *ngIf="followers?.length>5"> 
                                عرض الكل
                            </a>
                          </div>
                          <div>
                              <a *ngFor="let ss of followinggggg | slice:0:5">
                            <a  [routerLink]="['/timeline',ss.id]" >   
                                 <div class="contact-avatar">
                                     <img src="{{ss.image}}" alt="">
                               
                                  </div></a>
                                  <div class="contact-username"><a  [routerLink]="['/timeline',ss.id]"  style="color:#2d2d2d;cursor:pointer">{{ss.firstname}} {{ss.lastname}}</a></div>
                                  <a (click)="followit(ss.id)" *ngIf="ss.is_follow==false && ss.cat_name!=null" style="cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                    تابع
                                </a>
                
                                <a (click)="followit(ss.id)" *ngIf="ss.is_follow==true && ss.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                    متابعة
                                </a>
                              </a>
                        
                              <a  [routerLink]="'/Allfollowing'" class=" bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize mt-2" *ngIf="followinggggg?.length>5"> 
                                عرض الكل
                            </a>  
                           
                          </div>
                          
                
                       
                        </div>
                
                      
                
                  
                
                    </div>
                
                </div>
</div>
    </div>
</div>






  <!-- story-preview -->
  <div class="story-prev" >
  
    <div class="story-sidebar uk-animation-slide-left-medium">
        <div class="md:flex justify-between items-center py-2 hidden">
            <h3 class="text-2xl font-semibold">كل القصص </h3>
        </div>
  
        <div class="story-sidebar-scrollbar" data-simplebar>
  
           
  
  
            <div class="story-users-list"  uk-switcher="connect: #story_slider ; toggle: > * ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium ">
  
                <a href="#" *ngFor="let s of stories" (click)="story(s.post_id,s.image)">
                     <div class="story-media">
                  <img src="{{s.user_image}}" alt="">
                    </div>
                    <div class="story-text">
                        <div class="story-username">  {{s.firstname}} {{s.lastname}}</div>
                        <p>  <span class="story-time">{{s.created_at}}</span> </p>
                    </div>
                </a>
               
              
  
            </div>
  
  
        </div>
  
    </div>
    <div class="story-content">
  
        <ul class="uk-switcher uk-animation-scale-up" id="story_slider" >
            <li class="relative" style="display: block!important;">
  
           <!--      <span uk-switcher-item="previous" class="slider-icon is-left"> </span>
                <span uk-switcher-item="next" class="slider-icon is-right"> </span>
   -->
                <div uk-lightbox>
                    <a  data-alt="Image">
                        <img src="{{imageStory}}" class="story-slider-image"  data-alt="Image">
                    </a>
                </div>
  
            </li>
        
         
        </ul>
  
    </div>
  
    <!-- story colose button-->
    <span class="story-btn-close" uk-toggle="target: body ; cls: story-active"
        uk-tooltip="title:Close story ; pos: left">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    </span>
  
  </div> 
  








    <!-- open chat box -->
    <div uk-toggle="target: #offcanvas-chat" class="start-chat">
        <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
        </svg>
    </div>
    
    <div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true">
        <div class="uk-offcanvas-bar bg-white p-0 w-full lg:w-80">
    
    
            <div class="relative pt-5 px-4">
    
                <h3 class="text-2xl font-bold mb-2"> الدردشات </h3>
    
                <div class="absolute right-3 top-4 flex items-center">
    
                    <button class="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                        type="button" uk-close></button>
    
                    <a href="#" uk-toggle="target: #search;animation: uk-animation-slide-top-small">
                    </a>
                    <a href="#">
                    </a>
                    <a href="#">
                    </a>
    
                </div>
    
    
            </div>
    
            <div class="absolute bg-white z-10 w-full -mt-5 lg:mt-0 transform translate-y-1.5 py-2 border-b items-center flex"
                id="search" hidden>
                <input type="text" placeholder="Search.." class="flex-1">
  
            </div>
    
            <nav class="cd-secondary-nav border-b extanded mb-2">
                <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
                    <li class="uk-active"><a class="active" href="#0"> أصدقاء </a></li>
                    <li><a href="#0">مجموعات <span> 10 </span> </a></li>
                </ul>
            </nav>
    
            <div class="contact-list px-2 uk-switcher" id="chats-tab">
    
                <div>
    
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-1.jpg" alt="">
                        
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-2.jpg" alt="">
                            <span class="user_status"></span>
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-3.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-4.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
    
                </div>
                <div>
    
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-1.jpg" alt="">
                        
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-2.jpg" alt="">
                            <span class="user_status"></span>
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-3.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد أحمد</div>
                    </a>
                    <a href="timeline.html">
                        <div class="contact-avatar">
                            <img src="assets/images/avatars/avatar-4.jpg" alt="">
    
                        </div>
                        <div class="contact-username"> محمد علي</div>
                    </a>
    
                </div>
    
            </div>
        </div>
    </div>
<ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>