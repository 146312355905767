import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-writercats',
  templateUrl: './writercats.component.html',
  styleUrls: ['./writercats.component.css']
})
export class WritercatsComponent implements OnInit {
    toggle = true;
    status = "Enable";
    selectedButton = {}

  loading:boolean=true
 
  followers:any[]
  followinggggg:any[]
  users:any[]
  homedata
  token= localStorage.getItem('Token');
 role= localStorage.getItem('role');
 userid=localStorage.getItem('UserID')
 writercat:any[]

 postid
 p: number = 1;
 firstname= localStorage.getItem('firstname');
 lastname= localStorage.getItem('lastname');
 image= localStorage.getItem('image');
 writercat11
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService) {}


  ngOnInit(): void {
    this.spinner.show();



  this._service.writers_category()
  .subscribe(
    data => {
     let res: any = data["data"]


  

       this.writercat = res;
/*        let res1: any = data["data"][this.writercat.category_id]["users"];
       this.writercat11 = res1;
console.log("writercat11", this.writercat11) */


       this.loading=false
});


  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }
  followit(id,id1)
  {
    this._service.follow_NewUsers(id)
    .subscribe();
    this.selectedButton[id]= !this.selectedButton[id];
    id1.select = !id1.select;
  }

}
