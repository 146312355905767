<div data-layout="horizontal" data-topbar="colored" class="bg-body">

<div class="account-pages">
    <div class="container">
      
        <div class="row align-items-center justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card card-login card-login2">
                   
                    <div class="card-body"> 
                        <div class="text-center">
                            <h5>اختار نوع الحساب الخاص بيك</h5>
                        </div>
                        <div class="p-2 mt-4">
                            <form action="#">
                                <div class="form-group option-account">

                                    <div class="item-option">
                                       <a [routerLink]="'/userWriter'">    <img src="assets/images/contract.png">
                                        <p>كاتب محتوى</p></a>
                                    
                                    </div>
                                    <div class="item-option">
                                        <a [routerLink]="'/userRegistration'">
                                                
                                        <img src="assets/images/team.png">
                                        <p>مستخدم</p>
                                        </a>
                                   
                                    </div>
                                   
                                  
                                </div>

                            </form>
                        </div>
    
                    </div>
                </div>

            

            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>


</div>