
 <app-basic></app-basic>

 <!-- Main Contents -->
 <div class="main_content">
     <div class="mcontainer">
         
         <!--  Feeds  -->
         <div class="lg:flex lg:space-x-10">
           <div class="lg:w-3/4 lg:px-20 space-y-7">
       
            <div class="user_story grid lg:grid-cols-5 grid-cols-3 gap-2 lg:-mx-20 relative" >
          
                <a  uk-toggle="target: body ; cls: story-active"  style="cursor: pointer;" *ngFor="let s of stories| slice:0:5" >
                    <div class="single_story" (click)="story(s.post_id,s.image,s.user_image,s.firstname,s.lastname)">
                      <img src="{{s.image}}" alt="">
                        <div class="story-avatar">  <a [routerLink]="['/timeline',s.user_id]" > <img src="{{s.user_image}}" alt=""></a></div>
                        <div class="story-content"> <h4> {{s.firstname}}</h4> </div>
                    </div>
                </a>
       
      
            </div>
          
            <div >
          
            
      
            </div>
            <div >
            <div>
             
               
            </div>
          </div>
               <div class="bg-white shadow border border-gray-100 rounded-lg dark:bg-gray-900 lg:mx-0 uk-animation-slide-bottom-small" *ngFor="let s of posts " >
       
                   <div *ngIf="s.reshare==0">
                   <div class="flex justify-between items-center lg:p-4 p-2.5">
                       <div class="flex flex-1 items-center space-x-4">
                           <a  [routerLink]="['/timeline',s.user_id]" >
                               <img src="{{s.user_image}}" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                           </a>
                           <div class="flex-1 font-semibold capitalize">
                               <a  [routerLink]="['/timeline',s.user_id]" class="text-black">{{s.firstname}} {{s.lastname}}</a>
                               <div class="text-gray-700 flex items-center space-x-2"> {{s.new_createdAt}}</div>
                               <div class="text-gray-700 flex items-center space-x-2" *ngIf="s.cat_name!=null"> في مجال {{s.cat_name}}</div>

                           </div>
                       </div>
                     <div>
                       <a > <i class="  icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i> </a>
                       <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" 
                       uk-drop="mode: click;pos: bottom-left;animation: uk-animation-slide-bottom-small" >
                     
                           <ul class="space-y-1">
                             <li> 
                                 <a (click)="sharepost(s.id)" class="flex items-center share px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                  <i class="uil-share-alt mr-1"></i> مشاركة
                                 </a> 
                             </li>
                             <li *ngIf="s.user_id == userid && role=='2'" > 
                                 <a class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"  uk-toggle="target: #editPost" (click)="editpost(s.id)">
                                  <i class="uil-edit-alt mr-1"></i>  تعديل البوست 
                                 </a> 
                             </li>

                             <li *ngIf="s.user_id == userid  && role=='3'" > 
                                <a class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"  uk-toggle="target: #editPostwiter" (click)="editpost(s.id)">
                                 <i class="uil-edit-alt mr-1"></i>  تعديل البوست 
                                </a> 
                            </li>
                           
                             <li> 
                                 <a  (click)="favsreshared(s.id)" *ngIf="s.favorite==false" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                  <i class="uil-favorite mr-1"></i>  إضافة مفضلات
                                 </a> 

                                 <a style="color: #fb5a75;" (click)="favsreshared(s.id)" *ngIf="s.favorite==true" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                     <i class="uil-favorite mr-1"></i>  إزالة مفضلات
                                    </a> 
                             </li>
                             <li>
                               <hr class="-mx-2 my-2 dark:border-gray-800">
                             </li>
                             <li  (click)="deletepost(s.id)" *ngIf="s.user_id == userid"> 
                                 <a  class="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                  <i class="uil-trash-alt mr-1"></i>  مسح
                                 </a> 
                             </li>
                           </ul>
                       
                       </div>
                     </div>
                   </div>
                   <a   [routerLink]="['/singlepost',s.id]">

                   <div class="p-3 border-b dark:border-gray-700"  *ngIf="s.text!=null">
                     <p style="color: #2d2d2d;"> {{s.text}}</p>   
             
         
                     </div>
                 </a>
                   <div >
                    
                  

                       <video  controls src="{{s.video}}" frameborder="0"
                       uk-video="automute: true" allowfullscreen uk-responsive class="w-full lg:h-64 h-40"></video>
                    
                   </div>
       
        
                 
              

                   <div class="p-4 space-y-3 "> 
                  

                       <div class="flex space-x-4 lg:font-bold">
                           <a  *ngIf="s.likes==false"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                             <div> {{s.likes_num}} </div>

                               <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                       <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                   </svg>
                               </div>
                           </a>

                           <a *ngIf="s.likes==true"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                             <div  style="color:#2d86ff;">  {{s.likes_num}}</div>
                             <div class="p-2 rounded-full text-black lg:bg-gray-100" style="color:#2d86ff;">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                     <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                 </svg>
                             </div>
                           
                         </a>
                           <a class="flex  items-center space-x-2">
                             <div> {{s.comments_num}}</div>
                               <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                       <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                   </svg>
                               </div>
                             
                           </a>
                           <a  class="flex share items-center space-x-2 flex-1 justify-end">
                             <div> {{s.reshare_num}}</div>

                               <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                  <a  (click)="sharepost(s.id)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                       <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                   </svg></a>
                               </div>
                           </a>
                       </div>
              
                       <div   class="border-t py-4 space-y-4 dark:border-gray-600">
                           <div class="flex" *ngFor="let ss of s.comments| slice:0:3">
                               <div class="w-10 h-10 rounded-full relative flex-shrink-0">
                                   <img src="{{ss.image}}" alt="" class="absolute h-full rounded-full w-full">
                               </div>
                               <div>
                                   <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12  dark:bg-gray-800 dark:text-gray-100">
                                       <p class="leading-6">{{ss.comment}} </p>
                                       <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                                   </div>
                                  
                               </div>
                           </div>
                       
                       </div>
                    
                       <a  [routerLink]="['/singlepost',s.id]" class=" hover:text-blue-600 hover:underline" *ngIf="s.comments_num>3">  عرض {{s.comments_num-3}} مزيد من التعليقات </a>
       
                       <div class="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t"  >
                           <form #form="ngForm" (ngSubmit)="submitcomment(comment)">
                           <input #comment name="comment" placeholder="أضف تعليقك.." class="userclienttt bg-transparent max-h-10 shadow-none px-5"  (change)="oninsertcommentFile(s.id)" >
                          
                         </form>
                       </div>
               
                   </div>
                    </div>




                    <div *ngIf="s.reshare > 0">
                     <div class="flex justify-between items-center lg:p-4 p-2.5">
                         <div class="flex flex-1 items-center space-x-4">
                             <a [routerLink]="['/timeline',s.user_id]">
                                 <img src="{{s.user_image}}" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                             </a>
                             <div class="flex-1 font-semibold capitalize">
                                 <a [routerLink]="['/timeline',s.user_id]" class="text-black">{{s.firstname}} {{s.lastname}}</a>
                                 <div class="text-gray-700 flex items-center space-x-2"> {{s.new_createdAt}}</div>
                                 <div class="text-gray-700 flex items-center space-x-2" *ngIf="s.cat_name!=null"> في مجال {{s.cat_name}}</div>

                             </div>
                         </div>
                       <div>
                         <a > <i class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i> </a>
                         <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" 
                         uk-drop="mode: click;pos: bottom-left;animation: uk-animation-slide-bottom-small" >
                       
                             <ul class="space-y-1">
                               <li> 
                                   <a  (click)="sharepost(s.main_post.post_id)" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                    <i class="uil-share-alt mr-1"></i> مشاركة
                                   </a> 
                               </li>
                       
                             
                               <li> 
                                 <a  (click)="favsreshared(s.id)" *ngIf="s.favorite==false" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                  <i class="uil-favorite mr-1"></i>  إضافة مفضلات
                                 </a> 

                                 <a style="color: #fb5a75;" (click)="favsreshared(s.id)" *ngIf="s.favorite==true" class="flex share items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                     <i class="uil-favorite mr-1"></i>  إزالة مفضلات
                                    </a> 
                             </li>
                               <li>
                                 <hr class="-mx-2 my-2 dark:border-gray-800">
                               </li>
                               <li  (click)="deletepost(s.id)" *ngIf="s.user_id == userid"> 
                                   <a  class="flex items-center share px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                    <i class="uil-trash-alt mr-1"></i>  مسح
                                   </a> 
                               </li>
                             </ul>
                         
                         </div>
                       </div>
                     </div>

               
                     <div style="border: 1px solid gray;">
                         <div class="flex justify-between items-center lg:p-4 p-2.5">
                             <div class="flex flex-1 items-center space-x-4">
                                 <a [routerLink]="['/timeline',s.main_post.user_id]">
                                     <img src="{{s.main_post.user_image}}" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                                 </a>
                                 <div class="flex-1 font-semibold capitalize">
                                     <a [routerLink]="['/timeline',s.main_post.user_id]" class="text-black">{{s.main_post.firstname}} {{s.main_post.lastname}}</a>
                                     <div class="text-gray-700 flex items-center space-x-2"> {{s.main_post.new_createdAt}}</div>
                                     <div class="text-gray-700 flex items-center space-x-2" *ngIf="s.main_post.cat_name!=null">{{s.main_post.cat_name}} في مجال </div>
   
                                 </div>
                             </div>
                     
                         </div>
      
                         
     
                             <div>
                                 <a  [routerLink]="['/singlepost',s.main_post.post_id]">

                                 <div class="p-3 border-b dark:border-gray-700" *ngIf="s.text!=null">
                                     <p style="color: #2d2d2d;"> {{s.text}}</p>   
                             
                         
                                     </div>
                                 </a>
                                 <a  [routerLink]="['/singlepost',s.main_post.post_id]" *ngIf="s.image!=null">  
                                     <img src="{{s.image}}" alt="" class="max-h-96 w-full object-cover">
                                 </a>
       
                                 <video   controls *ngIf="s.video!=null" src="{{s.video}}" frameborder="0"
                                 uk-video="automute: true" allowfullscreen uk-responsive class="w-full lg:h-64 h-40"></video>
                                 <a  target="_blank" *ngIf="s.file!=null" href="{{s.file}}">  
                                   {{s.file}}
                                 </a>
                             </div>
                      
                             <div class="p-4 space-y-3"> 
                        
                                 <div class="flex space-x-4 lg:font-bold">
                                   
                                     <a  *ngIf="s.main_post.likes==false"  (click)="likedpost(s.main_post.post_id)"  class="flex share items-center space-x-2 flex-1 justify-end">
                                         <div> {{s.main_post.likes_num}} </div>
         
                                           <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                                   <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                               </svg>
                                           </div>
                                       </a>
         
                                       <a *ngIf="s.main_post.likes==true"  (click)="likedpost(s.main_post.post_id)"  class="flex share items-center space-x-2 flex-1 justify-end">
                                         <div  style="color:#2d86ff;">  {{s.main_post.likes_num}}</div>
                                         <div class="p-2 rounded-full text-black lg:bg-gray-100" style="color:#2d86ff;">
                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                                 <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                             </svg>
                                         </div>
                                       
                                     </a> 

 

                                     <a  class="flex  items-center space-x-2 justify-end">
                                         <div> {{s.main_post.comments_num}}</div>

                                         <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                                 <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                             </svg>
                                         </div>
                                     </a>
                                  
                                 </div>
                           </div>
                     
                     </div>
         

                   
                

                     <div class="p-4 space-y-3"> 
                  
                         <div class="flex space-x-4 lg:font-bold">
                           
                             <a  *ngIf="s.likes==false"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                                 <div> {{s.likes_num}} </div>
 
                                   <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                           <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                       </svg>
                                   </div>
                               </a>
 
                               <a *ngIf="s.likes==true"  (click)="likedpost(s.id)"  class="flex share items-center space-x-2">
                                 <div  style="color:#2d86ff;">  {{s.likes_num}}</div>
                                 <div class="p-2 rounded-full text-black lg:bg-gray-100" style="color:#2d86ff;">
                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                         <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                     </svg>
                                 </div>
                               
                             </a>
                             
                             
                             <a  class="flex items-center space-x-2">
                                 <div> {{s.comments_num}}</div>

                                 <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                         <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                     </svg>
                                 </div>
                             </a>
                             <a    class="flex items-center space-x-2 flex-1 justify-end">
                                 <div> {{s.reshare_num}}</div>

                                 <div class="p-2 rounded-full text-black lg:bg-gray-100">
                                   <a (click)="sharepost(s.main_post.post_id)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                         <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                     </svg></a>  
                                 </div>
                             </a>
                         </div>
                    
         
                         <div class="border-t py-4 space-y-4 dark:border-gray-600">
                             <div class="flex" *ngFor="let ss of s.comments | slice:0:3">
                                 <div class="w-10 h-10 rounded-full relative flex-shrink-0">
                                     <img src="{{ss.image}}" alt="" class="absolute h-full rounded-full w-full">
                                 </div>
                                 <div>
                                     <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-12  dark:bg-gray-800 dark:text-gray-100">
                                         <p class="leading-6">{{ss.comment}} </p>
                                         <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                                     </div>
                                    
                                 </div>
                             </div>
                         
                         </div>
                         <a  [routerLink]="['/singlepost',s.id]" class=" hover:text-blue-600 hover:underline" *ngIf="s.comments_num>3">  عرض {{s.comments_num-3}} مزيد من التعليقات </a>

         
                         <div class="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t"  >
                             <form #form="ngForm" (ngSubmit)="submitcomment(comment)">
                             <input #comment name="comment" placeholder="أضف تعليقك.." class="userclienttt bg-transparent max-h-10 shadow-none px-5"  (change)="oninsertcommentFile(s.id)" >
                            
                           </form>
                         </div>
                     </div>
                      </div>
     </div>
               
             
       
  

       
       
              <!--  <div class="flex justify-center mt-6">
                 <pagination-controls (pageChange)="p = $event"  (click)="scrollToTop()"></pagination-controls>
                  
               </div>
        -->
           </div>
           <div class="lg:w-72 w-full">
       
       
               <h3 class="text-xl font-semibold"> جهات الاتصال </h3>
       
               <div class="" uk-sticky="offset:80">

             
       
                   <nav class="cd-secondary-nav border-b extanded mb-2">
                       <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                           <li class="uk-active"><a class="active" href="#0">  المتابعين <span>{{homedata?.followers_num}}</span> </a></li>
                           <li><a href="#0" *ngIf="role!='2' || token== 'null'">التابعون  <span>{{homedata?.following_num}}</span> </a></li>
                       </ul>

                     
                   </nav>

              
       
                   <div class="contact-list uk-switcher userclienttt" id="group-details" >

                  
                     <div >
                      
                    <a *ngFor="let s of followers | slice:0:5">
                        <a  [routerLink]="['/timeline',s.id]" >  
                              <div class="contact-avatar">
                                 <img src="{{s.image}}" alt="">
                         
                             </div>
                             </a>
                             <div class="contact-username"><a  [routerLink]="['/timeline',s.id]"  style="color:#2d2d2d;" >  {{s.firstname}} {{s.lastname}}</a></div>
                             <a (click)="followit(s.id)" *ngIf="s.is_follow==false && s.cat_name!=null" style="cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                تابع

                             </a>

                             <a (click)="followit(s.id)" *ngIf="s.is_follow==true && s.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                 متابعة
                             </a>
                         </a>
                         <a  [routerLink]="'/Allfollowers'" class=" bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize mt-2"  *ngIf="followers?.length>5"> 
                            عرض الكل
                        </a>
                      
                     </div>
                     <div>
                         <a *ngFor="let ss of followinggggg | slice:0:5">
                            <a  [routerLink]="['/timeline',ss.id]" > 
                                    <div class="contact-avatar">
                                 <img src="{{ss.image}}" alt="">
                   
                             </div>
                             </a>
                             <div class="contact-username"><a  [routerLink]="['/timeline',ss.id]" style="color:#2d2d2d;" >  {{ss.firstname}} {{ss.lastname}}</a></div>
                             <a (click)="followit(ss.id)" *ngIf="ss.is_follow==false && ss.cat_name!=null" style="cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                تابع
                            </a>
            
                            <a (click)="followit(ss.id)" *ngIf="ss.is_follow==true && ss.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                متابعة
                            </a>
                         </a>
                    
                    
                         <a  [routerLink]="'/Allfollowing'" class=" bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize mt-2" *ngIf="followinggggg?.length>5"> 
                            عرض الكل
                        </a>   
                      
                     </div>
                     
       
                  
                   </div>

                 
       
              
       
               </div>
       
           </div>
         </div>
 
     </div>
 </div>
 
  <!-- story-preview -->
  <div class="story-prev" >
  
    <div class="story-sidebar uk-animation-slide-left-medium">
        <div class="md:flex justify-between items-center py-2 hidden">
            <h3 class="text-2xl font-semibold">كل القصص </h3>
        </div>
  
        <div class="story-sidebar-scrollbar" data-simplebar>
  
           
  
  
            <div class="story-users-list"  uk-switcher="connect: #story_slider ; toggle: > * ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium ">
  
                <a *ngFor="let s of stories" (click)="story(s.post_id,s.image)">
                     <div class="story-media">
                  <img src="{{s.user_image}}" alt="">
                    </div>
                    <div class="story-text">
                        <div class="story-username">  {{s.firstname}} {{s.lastname}}</div>
                        <p>  <span class="story-time">{{s.created_at}}</span> </p>
                    </div>
                </a>
               
              
  
            </div>
  
  
        </div>
  
    </div>
    <div class="story-content">
  
        <ul class="uk-switcher uk-animation-scale-up" id="story_slider" >
            <li class="relative" style="display: block!important;">
  
           <!--      <span uk-switcher-item="previous" class="slider-icon is-left"> </span>
                <span uk-switcher-item="next" class="slider-icon is-right"> </span>
   -->
                <div uk-lightbox>
                    <a  data-alt="Image">
                        <img src="{{imageStory}}" class="story-slider-image"  data-alt="Image">
                    </a>
                </div>
  
            </li>
        
         
        </ul>
  
    </div>
  
    <!-- story colose button-->
    <span class="story-btn-close" uk-toggle="target: body ; cls: story-active"
        uk-tooltip="title:Close story ; pos: left">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    </span>
  
  </div> 
  

<!-- open chat box -->
<div uk-toggle="target: #offcanvas-chat" class="start-chat">
 <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
 </svg>
</div>

<div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true">
 <div class="uk-offcanvas-bar bg-white p-0 w-full lg:w-80">


     <div class="relative pt-5 px-4">

         <h3 class="text-2xl font-bold mb-2"> الدردشات </h3>

         <div class="absolute right-3 top-4 flex items-center">

             <button class="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                 type="button" uk-close></button>

           

         </div>


     </div>

     <div class="absolute bg-white z-10 w-full -mt-5 lg:mt-0 transform translate-y-1.5 py-2 border-b items-center flex"
         id="search" hidden>
         <input type="text" placeholder="Search.." class="flex-1">

     </div>

     <nav class="cd-secondary-nav border-b extanded mb-2">
         <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
             <li class="uk-active"><a class="active" href="#0"> أصدقاء </a></li>
             <li><a href="#0">مجموعات <span> 10 </span> </a></li>
         </ul>
     </nav>

     <div class="contact-list px-2 uk-switcher" id="chats-tab">

         <div>

             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-1.jpg" alt="">
 
                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-2.jpg" alt="">
                     <span class="user_status"></span>
                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-3.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-4.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>

         </div>
         <div>

             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-1.jpg" alt="">
                   
                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-2.jpg" alt="">
                     <span class="user_status"></span>
                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-3.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-4.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>

         </div>

     </div>
 </div>
</div>

<!-- editPost -->
<div id="editPost" class="create-post" uk-modal style="overflow-x: scroll;">
 <div style="overflow-x: scroll;"
     class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

     <div class="text-center py-4 border-b">
         <h3 class="text-lg font-semibold"> تعديل منشور </h3>
         <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton2></button>
     </div>
     <form #form="ngForm" (ngSubmit)="submiteditpost(edittect)">
     <div class="flex flex-1 items-start space-x-4 p-5">
         <img src="{{image}}"
             class="bg-gray-200 border border-white rounded-full w-11 h-11">
         <div class="flex-1 pt-2">
             <textarea  #edittect name="edittect" maxlength="140"  class="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="4"
                 placeholder="ما هو رأيك؟ {{firstname}}!" value={{singlePOST?.text}}>
        

             </textarea>
             <div id="count">
          
                 <span id="maximum_count">140</span>
             </div>
                 <img *ngIf="singlePOST?.image || formatedit==='image'  " src="{{singlePOST?.image}}" style="width: 100%;" class="img-fluid">
                 <a *ngIf="singlePOST?.file || formatedit==='File'  " href="{{singlePOST?.file}}" >{{singlePOST?.file}}</a>
                 <video *ngIf="singlePOST?.video || formatedit==='video' " src="{{singlePOST?.video}}"   style="width: 100%;"></video>

        
         </div>


     </div>
  


     <div class="bsolute bottom-0 p-4 space-x-4 w-full">
         <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-3 shadow-sm items-center">
             <div class="lg:block hidden">أضف إلى منشورك </div>
             <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                  <span class="test" id="preview"> 
                       <a> 
                         <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                       <input (change)="onSeditFile($event)"  style="opacity: 0" type="file"  accept="/*"/>
                         </a>
                    </span>

           
             </div>
         </div>
     </div>
     <div class="flex items-center w-full justify-between p-3 border-t">
       <button id="closebutton2" type="submit"
             class="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
             تعديل </button>
     </div>
 </form>
 </div>
</div>



 <!-- editPost -->
 <div id="editPostwiter" class="create-post" uk-modal style="overflow-x: scroll;">
    <div style="overflow-x: scroll;"
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
  
        <div class="text-center py-4 border-b">
            <h3 class="text-lg font-semibold"> تعديل منشور </h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 m-1 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7" #closebutton3></button>
        </div>
        <form #form="ngForm" (ngSubmit)="submiteditpost1(editpostwriter)">
        <div class="flex flex-1 items-start space-x-4 p-5">
            <img src="{{image}}"
                class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <div class="flex-1 pt-2">
                <textarea  #editpostwriter    class="uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="4"
                    placeholder="ما هو رأيك؟ {{firstname}}!" value={{singlePOST?.text}}>
           

                </textarea>
            
                    <img *ngIf="singlePOST?.image || formatedit==='image'  " src="{{singlePOST?.image}}" style="width: 100%;" class="img-fluid">
                    <a *ngIf="singlePOST?.file || formatedit==='File'  " href="{{singlePOST?.file}}" >{{singlePOST?.file}}</a>
                    <video *ngIf="singlePOST?.video || formatedit==='video' " src="{{singlePOST?.video}}"   style="width: 100%;"></video>

           
            </div>

  
        </div>
     


        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-3 shadow-sm items-center">
                <div class="lg:block hidden">أضف إلى منشورك </div>
                <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                     <span class="test" id="preview"> 
                          <a> 
                            <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          <input (change)="onSeditFile($event)"  style="opacity: 0" type="file"  accept="/*"/>
                            </a>
                       </span>

              
                </div>
            </div>
        </div>
        <div class="flex items-center w-full justify-between p-3 border-t">
          <button id="closebutton3" type="submit"
                class="bg-blue-600 flex h-9 items-center justify-center rounded-lg text-white px-12 font-semibold">
                تعديل </button>
        </div>
    </form>
    </div>
  </div>
<ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>