import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading:boolean=false
  rememberme:boolean=false
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router) {}

  ngOnInit(): void {
    this.spinner.show();

  }
  checkCheckBoxvalue(event){
    this.rememberme=event.target.checked
    console.log("dddd",event.target.checked)
  }

  submitForm(email,password){
    this.loading=true

   this._service.Login(email.value,password.value).subscribe(
    data=>  {
   if(this.rememberme==true)
      {
        localStorage.setItem('email',email.value);
        localStorage.setItem('password',password.value);

      }

      else{
        localStorage.setItem('email','');
        localStorage.setItem('password','');
      } 

        let error:number= data["error"];
        let message:string= data["message"];

        if(error==0){
         
            Swal.fire({
              icon: 'success',
              title: message,
              showConfirmButton: false,
              timer: 1500
            })
       this.loading=false
       let res:any=data["data"];
            let Token:string= res["token"];
            let UserID:number= res["id"];
            let firstname:string= res["firstname"];
            let lastname:string= res["lastname"];
            let Phone:string= res["phone"];
            let Email:string= res["email"];
            let image:string= res["image"];
            let role:string= res["role"];
            let state:string= res["state"];


            localStorage.setItem('res',res);

            localStorage.setItem('Token',Token);
            localStorage.setItem('UserID',JSON.stringify(UserID));
            localStorage.setItem('firstname',firstname);
            localStorage.setItem('lastname',lastname);

            localStorage.setItem('Phone',Phone);
            localStorage.setItem('Email',Email);
            localStorage.setItem('image',image);
            localStorage.setItem('role',role);
            localStorage.setItem('state',state);
       this.router.navigate(['/Home']).then(() => {
            window.location.reload();
          });  

            }
       else {

        Swal.fire({
          icon: 'error',
          title: message,
        })
        this.loading=false

        }
      }

    );

         
  }
}
