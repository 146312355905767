import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  loading:boolean=true
  token= localStorage.getItem('Token');
  role= localStorage.getItem('role');
  userid=localStorage.getItem('UserID')
  firstname= localStorage.getItem('firstname');
  lastname= localStorage.getItem('lastname');
  image= localStorage.getItem('image');
  all_videos:any[];
  my_videos:any[]
  stories:any[]
  followers:any[]
  followinggggg:any[]
  homedata:any
  IDStory
  imageStory
  p: number = 1;
   constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService) {}
 
   
 
 
   ngOnInit(): void {
     this.spinner.show();
     this._service.Video_page()
      .subscribe(
        data => {
          let res: any = data["data"]

          let resources: any = data["data"]["all_videos"];
          let resources1: any = data["data"]["my_videos"];
          let resources2: any = data["data"]["all_stories"];
          let resources3: any = data["data"]["followers"];
          let resources4: any = data["data"]["following"];
          this.homedata = res;

           this.all_videos = resources;
           this.my_videos = resources1;
           this.stories = resources2;
           this.followers = resources3;
           this.followinggggg = resources4;

         
           this.loading=false
   });
 
 
 
  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }
 
  
  story(id,imageSt)
{

this.IDStory=id
this.imageStory=imageSt
console.log("IDStory",id,imageSt)
}
 
 
followit(id)
{
  this._service.follow_users(id)
  .subscribe(); 
  this.ngOnInit()
}

  
 
  

 
 
 }
 