<div class="bg-body">
    <div class="account-pages">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-4" style="margin-top: 82px;margin-bottom: 80px;">
                    <div class="card card-login card-pass">
                        <a href="#" class="auth-logo">
                            <img src="assets/images/forget-password.png" style="width: 110px;" alt="" class="logo">
                        </a>
                        <div class="card-body"> 
                            <div class="text-center">
                                <h5>هل نسيت كلمة السر</h5>
                            </div>
                            <div class="p-2 mt-4">
                                <form #form="ngForm" (ngSubmit)="submitForm(phone,password,confirmpassword)">
    
                                    <div class="form-group position-relative">
                                        <input type="text" class="form-control" id="phone" name="phone" #phone placeholder="ادخل رقم الهاتف">
                                        <img class="icon-input" src="assets/images/phone.png">
                                    </div>
                                    <div class="form-group position-relative">
                                        <input type="password" class="form-control" #password name="password"  placeholder="كلمة السر الجديدة">
                                        <img class="icon-input" src="assets/images/password.png">
                                    </div>
                                    <div class="form-group position-relative">
                                        <input type="password" class="form-control" #confirmpassword name="password"  placeholder="تأكيد كلمة السر">
                                        <img class="icon-input" src="assets/images/password.png">
                                    </div>
                                    
                                    <div class="mt-5 text-center mb-10">
                                        <button class="btn btn-primary w-sm waves-effect waves-light" type="submit">ارسال  </button>
                                    </div>
        

                                    <div class="text-center mt-3">
                                        <p class="mb-0"><a [routerLink]="'/login'"  class="btn-new-account">تسجيل الدخول في الحساب؟</a> </p>
                                    </div>
                                </form>
                            </div>
        
                        </div>
                    </div>

                

                </div>
            </div>
            
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>

</div>


<ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>