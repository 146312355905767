
 <app-basic></app-basic>

 <!-- Main Contents -->
 <div class="main_content">
     <div class="mcontainer">
         
         <!--  Feeds  -->
         <div class="lg:flex lg:space-x-10">
           <div class="lg:w-4/4 lg:px-20 space-y-7">
          
   
            <div dir="rtl">

                <ul style="text-align: right;margin-top: 20px;" class="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
             
                    <li  *ngFor="let s of followers" style="text-align: right;margin-top: 20px;margin-bottom: 20px;">
                        <a style="display: block;" >
                           <a   [routerLink]="['/timeline',s.id]"> <img src="{{s.image}}" class="w-full h-48 rounded-lg shadow-sm object-cover"></a>
                            <div class="pt-2" >
                             <h4 class="text-lg font-semibold">    <a   [routerLink]="['/timeline',s.id]"> {{s.firstname}} {{s.lastname}}</a> </h4>
                               <a (click)="followit(s.id)" *ngIf="s.is_follow==false && s.cat_name!=null" style="cursor: pointer;" class=" flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                            تابع
                            </a>

                            <a (click)="followit(s.id)" *ngIf="s.is_follow==true && s.cat_name!=null"  style="background-color: #5c73e8;color: #fff;cursor: pointer;" class="  flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
                                متابعة
                            </a>
                            </div>
                       </a>
                    </li>
                  
        
                </ul>
          
              </div>
               
  
                
          
           
           
    
            
              
    
        
    
           
       
        
       

        

       
          
       
           </div>
       
         </div>
 
     </div>
 </div>
 



<!-- open chat box -->
<div uk-toggle="target: #offcanvas-chat" class="start-chat">
 <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
 </svg>
</div>

<div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true">
 <div class="uk-offcanvas-bar bg-white p-0 w-full lg:w-80">


     <div class="relative pt-5 px-4">

         <h3 class="text-2xl font-bold mb-2"> الدردشات </h3>

         <div class="absolute right-3 top-4 flex items-center">

             <button class="uk-offcanvas-close  px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok"
                 type="button" uk-close></button>

             <a href="#" uk-toggle="target: #search;animation: uk-animation-slide-top-small">
              
             </a>
             <a href="#">
               
             </a>
             <a href="#">
              
             </a>

         </div>


     </div>

     <div class="absolute bg-white z-10 w-full -mt-5 lg:mt-0 transform translate-y-1.5 py-2 border-b items-center flex"
         id="search" hidden>
         <input type="text" placeholder="Search.." class="flex-1">

     </div>

     <nav class="cd-secondary-nav border-b extanded mb-2">
         <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
             <li class="uk-active"><a class="active" href="#0"> أصدقاء </a></li>
             <li><a href="#0">مجموعات <span> 10 </span> </a></li>
         </ul>
     </nav>

     <div class="contact-list px-2 uk-switcher" id="chats-tab">

         <div>

             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-1.jpg" alt="">
                 
                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-2.jpg" alt="">
                     <span class="user_status"></span>
                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-3.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-4.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>

         </div>
         <div>

             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-1.jpg" alt="">
          
                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-2.jpg" alt="">
                     <span class="user_status"></span>
                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-3.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد أحمد</div>
             </a>
             <a href="timeline.html">
                 <div class="contact-avatar">
                     <img src="assets/images/avatars/avatar-4.jpg" alt="">

                 </div>
                 <div class="contact-username"> محمد علي</div>
             </a>

         </div>

     </div>
 </div>
</div>




<ngx-spinner *ngIf="loading" bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-pulse-sync" [fullScreen] = "false"><p style="color: white" >...جاري التحميل</p></ngx-spinner>