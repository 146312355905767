import { Component, OnInit,ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiservicesService } from '../apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.css']
})
export class SinglePostComponent implements OnInit {
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;

  loading:boolean=true
  stories:any[]
  posts:any[]
  followers:any[]
  following:any[]
  homedata
  token= localStorage.getItem('Token');
 role= localStorage.getItem('role');
 userid=localStorage.getItem('UserID')
 singlePOST:any
 test11
 postid
 singlepost
 imageedit
 formatedit
 ImageURl
 firstname= localStorage.getItem('firstname');
 lastname= localStorage.getItem('lastname');
 image= localStorage.getItem('image');
  constructor(private spinner: NgxSpinnerService,private _service: ApiservicesService, private router: Router,private toastr: ToastrService,private route:ActivatedRoute) {}
  onSeditFile(event) {

    const file = event.target.files&& event.target.files[0];
    if(event.target.files.length  > 0) 
    {
      console.log(event.target.files[0].name);
      this.test11 =event.target.files[0].name
      if (file) {

        this.imageedit =file
  
        var reader = new FileReader();
        if(file.type.indexOf('image')> -1){
          this.formatedit = 'image';
        } else if(file.type.indexOf('video')> -1){
          this.formatedit = 'video';
        }
        else{
          this.formatedit = 'File';
        }
        reader.onload = (event) => {
          this.ImageURl = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(file);
      }
    }


    
  }

  ngOnInit(): void {
    this.singlepost=+this.route.snapshot.paramMap.get('id');

    this.spinner.show();
     console.log("userid",this.userid)
    this._service.HomeData()
     .subscribe(
       data => {
        let res: any = data["data"];

         let resources: any = data["data"]["stories"];
         let resources2: any = data["data"]["followers"];
         let resources3: any = data["data"]["following"];
         this.homedata = res;

          this.stories = resources;
          this.followers = resources2;
          this.following = resources3;
          this.loading=false
  });

  this._service.post_ById(this.singlepost)
  .subscribe(
    data => {
     let res: any = data["data"];
      this.singlePOST = res;
       this.loading=false
});


  var UserID=localStorage.getItem('UserID')

  if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {
    $(".loginbtn").css("opacity", "1")
    $(".userclienttt").css("opacity", "0")
   

      console.log("logouttttttttt")
   }
     else if (UserID!= null || UserID != ""|| UserID != "") {
      
      
        $(".loginbtn").css("opacity", "0")
       $(".userclienttt").css("opacity", "1")
      

       console.log("loginnnnnn -> ",localStorage.getItem('UserID'))
       console.log("loginnnnnn",UserID.length)
      }
  
      else
  
      {
        $(".loginbtn").css("opacity", "1")
       $(".userclienttt").css("opacity", "0")
    

       console.log("loginnnnnn",UserID)
       console.log("logouttttttttt")
  
  
      }
  }
  editpost(id)
  {
    this.singlepost=id
    console.log("singlepost",id)
    
    this._service.post_ById(this.singlepost)
    .subscribe(
      data => {
       let res: any = data["data"];
        this.singlePOST = res;
         this.loading=false
  });
  }

  sharepost(id)
  {

    var UserID=localStorage.getItem('UserID')
    if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {

    this.router.navigate(['/login'])
    }

    else
    {

      this._service.reshare_post(id)
      .subscribe(

        data=>  {
          Swal.fire({
            icon: 'success',
            title: 'تم مشاركة المنشور بنجاح',
            showConfirmButton: false,
            timer: 1500
          })
        }
      ); 
      this.ngOnInit()
    }

  }



  favsreshared(id)
  {
    var UserID=localStorage.getItem('UserID')
    if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {

    this.router.navigate(['/login'])
    }

    else
    {

      this._service.make_favorite(id)
      .subscribe(); 
      this.ngOnInit()
    }

  }
  likedpost(id)
  {

    var UserID=localStorage.getItem('UserID')
    if (UserID=== null || UserID === "null"|| UserID === "" || UserID.length <= 0) {

    this.router.navigate(['/login'])
    }

    else
    {

      this._service.like_post(id)
      .subscribe(); 
      this.ngOnInit()
    }

  }

  

  oninsertcommentFile(id)
  {
    console.log("test",id)
    this.postid=id

  }
  submitcomment(comment){
    console.log("form",comment.value,this.postid)
  this._service.add_comment(this.postid,comment.value).subscribe(
      data=>  {

        let error:number= data["error"];
        let message:string= data["message"];

        if(error==0)
        {
        
         this.ngOnInit()
       }

        else
        {
           Swal.fire({
              icon: 'error',
              title: '...عذرا',
              text:message,
            })



          this.loading=false;


        }




      }

    );
  }

  followit(id)
  {
    this._service.follow_users(id)
    .subscribe(); 
    this.ngOnInit()
  }
  submiteditpost(edittect){
    console.log("fff",this.imageedit,edittect.value,this.singlepost)
    this.loading=true;
  this._service.edit_post(this.imageedit,edittect.value,this.singlepost).subscribe(
      data=>  {

        let error:number= data["error"];
        let message:string= data["message"];

        if(error==0)
        {

          Swal.fire({
              icon: 'success',
              title:message ,
              showConfirmButton: false,
              timer: 2000
            })
            this.loading=false;
            this.closebutton2.nativeElement.click();

            this.ngOnInit()

          }

        else
        {
           Swal.fire({
              icon: 'error',
              title: '...عذرا',
              text:message,
            })



          this.loading=false;


        }




      }

    );
  }


  submiteditpost1(editpostwriter){
    console.log("fff",this.imageedit,editpostwriter.value,this.singlepost)
    this.loading=true;
  this._service.edit_post(this.imageedit,editpostwriter.value,this.singlepost).subscribe(
      data=>  {

        let error:number= data["error"];
        let message:string= data["message"];

        if(error==0)
        {

          Swal.fire({
              icon: 'success',
              title:message ,
              showConfirmButton: false,
              timer: 2000
            })
            this.loading=false;
            this.closebutton3.nativeElement.click();

            this.ngOnInit()

          }

        else
        {
           Swal.fire({
              icon: 'error',
              title: '...عذرا',
              text:message,
            })



          this.loading=false;


        }




      }

    );
  }
}
